import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { useHistory, useParams } from 'react-router-dom';
import getTeamFormInput from '../Teams/getTeamFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
import { FaPlus, FaSearch, FaTrash, FaUndoAlt } from 'react-icons/fa';
import translations from '../../translations/de';
import TabSwitcher from '../../components/TabSwitcher';
import StaffTab from '../Teams/StaffTab';
import Delayed from '../Teams/Delayed';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import API from '../../api/teams';

const Team = ({ store }) => {
    const history = useHistory();
    const { team_id } = useParams();
    const [playerID, setPlayerID] = useState(null);
    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);

    useEffect(() => {
        store.getTeam(team_id);
    }, [store, team_id]);

    const teamModel = clearObjProps({ ...store.selectedTeam }, ['id', 'players']);

    for (const key in teamModel) {
        if (
            (key === 'away_color' ||
                key === 'away_text_color' ||
                key === 'home_color' ||
                key === 'home_text_color') &&
            teamModel[key] === null
        ) {
            teamModel[key] = '#FFFFFF';
        }
    }

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editTeam({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    const handlePlayer = (id) => {
        history.push(`/teams-female/${store.selectedTeam.id}/players/${id}`);
    };

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const deletePlayer = () => {
        try {
            API.deletePlayer(playerID).then(() => {
                var temp = [...store?.selectedTeam?.players];
                temp.forEach((member) => {
                    if (member.id === playerID) {
                        store?.selectedTeam?.editPlayer({ ...member, deleted_at: 'deleted' });
                    }
                });
            });
        } catch (e) {
            console.log(e);
        }
    };

    const revivePlayer = (id) => {
        try {
            API.revivePlayer(id).then(() => {
                store.selectedTeam.players.forEach((element) => {
                    if (element.id === id) {
                        store?.selectedTeam?.editPlayer({
                            ...element,
                            deleted_at: null,
                        });
                    }
                });
            });
        } catch (e) {
            console.log(e);
        }
    };

    return store.selectedTeam ? (
        <Delayed>
            <>
                <Item
                    links={[{ path: '/teams-female', name: translations.teams }]}
                    name={store.selectedTeam.title}
                    id={`[ team_ID: ${store.selectedTeam.id} ]`}
                    itemModel={{
                        ...teamModel,
                    }}
                    edit={edit}
                    onSubmit={onSubmitHandler}
                    onEdit={() => setEdit(true)}
                    onCancel={() => {
                        error && setError(null);
                        setEdit(false);
                    }}
                    error={error}
                    loading={loading}
                    insertDummyFieldAt={3}
                    determineInputType={({
                        value,
                        name,
                        control,
                        register,
                        error,
                        disabled,
                        index,
                    }) =>
                        getTeamFormInput({
                            value,
                            name,
                            control,
                            register,
                            error,
                            disabled,
                            index,
                            resetValue: (name, value) => setResetProp({ [name]: value }),
                        })
                    }
                    resetProp={resetProp}
                >
                    <ItemImage imgSrc={store.selectedTeam.logo_url} />
                </Item>

                {
                    <TabSwitcher initialTabName={translations.player_female}>
                        <TabSwitcher.Tabs>
                            <TabSwitcher.TabPill tabName={translations.player_female} />
                            <TabSwitcher.TabPill tabName={translations.staff} />
                        </TabSwitcher.Tabs>
                        <TabSwitcher.TabPanel activeWhenTabName={translations.player_female}>
                            <div style={{ marginLeft: '2.5%' }}>
                                <Button
                                    label={translations.player_female}
                                    onClick={() => {
                                        store.selectPlayer(undefined);
                                        history.push(
                                            `/teams-female/${store.selectedTeam.id}/new_player`,
                                        );
                                    }}
                                >
                                    <FaPlus style={{ marginRight: '5px' }} />
                                </Button>
                            </div>
                            {store.selectedTeam.players.length !== 0 && (
                                <Table
                                    columns={[
                                        {
                                            Header: 'ID',
                                            accessor: 'id',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: translations.avatar,
                                            accessor: 'avatar',
                                            Cell: ({ value, row }) => (
                                                <div>
                                                    <ItemImage
                                                        imgSrc={value}
                                                        isAvatar={true}
                                                        listView={true}
                                                    />
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: translations.first_name,
                                            accessor: 'first_name',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: translations.last_name,
                                            accessor: 'last_name',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: translations.postion,
                                            accessor: 'position',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: translations.number,
                                            accessor: 'number',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: '',
                                            accessor: 'action',
                                            Cell: ({ row }) => (
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            handlePlayer(row.cells[0].value);
                                                        }}
                                                        title={translations.show}
                                                    >
                                                        <FaSearch />
                                                    </Button>
                                                    {isNotDeletedAlready(row) ? (
                                                        <Button
                                                            title={translations.delete}
                                                            onClick={() => {
                                                                setPlayerID(row.cells[0].value);
                                                                setModal(true);
                                                            }}
                                                        >
                                                            <FaTrash />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={() =>
                                                                revivePlayer(row.cells[0].value)
                                                            }
                                                        >
                                                            <FaUndoAlt />
                                                        </Button>
                                                    )}
                                                </div>
                                            ),
                                        },
                                    ]}
                                    data={store.selectedTeam.players.map(
                                        ({
                                            id,
                                            avatar,
                                            first_name,
                                            last_name,
                                            position,
                                            number,
                                            deleted_at,
                                        }) => {
                                            return {
                                                id,
                                                avatar,
                                                first_name,
                                                last_name,
                                                position,
                                                number,
                                                deleted_at,
                                            };
                                        },
                                    )}
                                />
                            )}
                            {modal && (
                                <Modal
                                    title="Delete Player"
                                    ref={modalRef}
                                    onClose={() => setModal(false)}
                                    maxWidth="400px"
                                >
                                    <p style={{ color: '#333', marginBottom: '20px' }}>
                                        {`Are you sure that you want to delete player with id ${playerID}?`}
                                    </p>
                                    <div className="Modal-Controls">
                                        <Button
                                            label="Yes"
                                            onClick={() => {
                                                deletePlayer();
                                                setModal(false);
                                            }}
                                            style={{ width: '80px' }}
                                        />
                                        <Button
                                            label="No"
                                            onClick={() => setModal(false)}
                                            style={{ width: '80px', marginLeft: '20px' }}
                                        />
                                    </div>
                                </Modal>
                            )}
                        </TabSwitcher.TabPanel>
                        <TabSwitcher.TabPanel activeWhenTabName={translations.staff}>
                            <StaffTab />
                        </TabSwitcher.TabPanel>
                    </TabSwitcher>
                }
            </>
        </Delayed>
    ) : null;
};

export default observer(Team);
