import { useState, useEffect } from 'react';

const useModal = (initial, modalRef = null, disabled = false, disableScroll = true) => {
    const [modal, setModal] = useState(initial);

    useEffect(() => {
        if (disableScroll) {
            document.querySelector('html').style.overflow = modal ? 'hidden' : 'auto';
            document.querySelector('html').style.paddingRight = modal ? '15px' : '0';
        }
    }, [modal, disableScroll]);

    useEffect(() => {
        const handleClickOutsideModal = (event) => {
            // close Modal if it is cicked outside the Modal
            if (
                modalRef &&
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !disabled
            ) {
                setModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideModal);
        return () => document.removeEventListener('mousedown', handleClickOutsideModal);
    }, [modalRef, disabled]);

    return [modal, (value) => setModal(value)];
};

export default useModal;
