import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import getTeamFormInput from '../Teams/getTeamFormInput';
import translations from '../../translations/de';

const NewTeam = ({ store }) => {
    const history = useHistory();
    const [logoUrl, setLogoUrl] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    return (
        <Item
            links={[{ path: '/teams-female', name: translations.teams }]}
            name={`${translations.team} ${translations.create}`}
            itemModel={{ title: '', logo_url: logoUrl }}
            edit={true}
            onSubmit={(event) => {
                error && setError(null);
                setLoading(true);
                event.league = 'women league';
                store
                    .addTeam(event)
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.TEAMS_FEMALE);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ name, register, error, disabled, index }) =>
                getTeamFormInput({
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setLogoUrl({ [name]: value }),
                })
            }
            resetProp={logoUrl}
        ></Item>
    );
};

export default NewTeam;
