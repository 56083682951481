const Paths = {
    LOGIN: '/login',
    HOME: '/home',
    FORGOT_PASSWORD: '/forgot-password',
    ADMINS: '/admins',
    ADMIN: '/admins/:admin_id',
    NEW_ADMIN: '/admins/admin/new',
    FANS: '/fans',
    FAN: '/fans/:user_id',
    NEWSLETTER: '/newsletter',
    TEAMS: '/teams',
    TEAM: '/teams/:team_id',
    NEW_TEAM: '/teams/team/new',
    TEAM_PLAYER: '/teams/:team_id/players/:player_id',
    TEAM_NEW_PLAYER: '/teams/:team_id/new_player',
    NEW_STAFF_MEMBER: '/teams/:team_id/new_staff_member',
    STAFF_MEMBER: '/teams/:team_id/staff/:staff_member_id',
    STAFF: '/teams/:team_id/staff',
    SHOP: '/shop',
    SHOP_ITEM: '/shop/:item_id',
    SHOP_NEW_ITEM: '/shop/item/new',
    REWARDS: '/rewards',
    REWARD: '/rewards/:reward_id',
    NEW_REWARD: '/rewards/reward/new_reward',
    SEASONS: '/seasons',
    SEASON: '/seasons/:season_id',
    NEW_SEASON: '/seasons/season/new',
    SEASON_NEW_FAN_GAME: '/seasons/:season_id/new_fan_game',
    SEASON_FAN_GAME: '/seasons/:season_id/fan-game/:fan_game_id',
    MATCHES: '/matches',
    MATCH: '/matches/:match_id',
    NEW_MATCH: '/matches/match/new',
    MATCH_FAN_GAME: '/matches/:match_id/fan-game/:fan_game_id',
    MATCH_NEW_FAN_GAME: '/matches/:match_id/new_fan_game',
    NOTIFICATION: '/notifications/:notification_id',
    NOTIFICATIONS: '/notifications',
    NEW_NOTIFICATION: '/notifications/notification/new',
    PUSH_NOTIFICATIONS: '/push-notifications',
    SECTORS: '/sectors',
    SECTOR: '/sectors/:sector_id',
    NEW_SECTOR: '/sectors/sector/new',
    SEAT_GROUPS: '/seat-groups', //blocks
    SEAT_GROUP: '/seat-groups/:group_id',
    NEW_SEAT_GROUP: '/seat-groups/seat-group/new',
    SEATS: '/seats',
    SEAT: '/seats/:seat_id',
    NEW_SEAT: '/seats/seat/new',
    SEAT_MAPS: '/seat-maps',
    SEAT_MAP: '/seat-maps/:seat_map_id',
    NEW_SEAT_MAP: '/seat-maps/seat-map/new',
    SCREENS: '/screens',
    SCREEN: '/screens/:screen_id',
    NEW_SCREEN: '/screens/screen/new',
    SCREEN_BANNER: '/screens/:screen_id/banners/:banner_id',
    SCREEN_NEW_BANNER: '/screens/:screen_id/new_banner',
    SCANNERS: '/scanners',
    SCANNER: '/scanners/:scanner_id',
    NEW_SCANNER: '/scanners/scanner/new',
    TICKETING_KPI: '/ticketing-kpi',
    MARKETING_KPI: '/marketing-kpi',
    PASSES: '/abos',
    PASS: '/abos/:abo_id',
    NEW_PASS: '/abos/abo/new',
    PASSES_F: '/abos_freunde_ticket',
    PASS_F: '/abos_freunde_ticket/:abo_id',
    NEW_PASS_F: '/abos_freunde_ticket/abo/new',
    MATCH_OVERVIEW: '/match_overview',
    E_TICKETS: '/etickets',
    E_TICKET: '/etickets/:eticket_id',
    NEW_E_TICKET: '/etickets/eticket/new',
    BUSINESS_PROFILES: '/business-profiles',
    BUSINESS_PROFILE: '/business-profiles/:profile_id',
    NEW_BUSINESS_PROFILE: '/business-profiles/profile/new',
    BUSINESS_PARTNERS: '/business-partners',
    BUSINESS_PARTNER: '/business-partners/:partner_id',
    BUSINESS_PARTNER_NEW_PROFILE: '/business-partners/:partner_id/new_business_profile',
    NEW_BUSINESS_PARTNER: '/business-partners/partner/new',
    BUSINESS_EVENTS: '/business-events',
    BUSINESS_EVENT: '/business-events/:event_id',
    NEW_BUSINESS_EVENT: '/business-events/event/new',
    INSTANT_REWARDS: '/instant-rewards',
    INSTANT_REWARD: '/instant-rewards/:reward_id',
    NEW_INSTANT_REWARD: '/instant-rewards/instant-reward/new',
    MATCH_STATISTICS: '/match-statistics',
    ANALYTICS: '/analytics',
    FAN_GAMES: '/fan-games',
    FAN_GAME: '/fan-games/:fan_game_id',
    FAN_GAME_NEW_REWARD: '/fan-games/:fan_game_id/new_reward',
    FAN_GAME_END_GAME_REWARDS: '/fan-games/:fan_game_id/end-game-result-rewards',
    FAN_GAME_END_GAME_RESULT_WINNERS: '/fan-games/:fan_game_id/end-game-result-winners',
    FAN_GAME_END_GAME_RANKING: '/fan-games/:fan_game_id/end-game-result-ranking',
    FAN_GAME_NEW_DEFAULT_REWARD: '/fan-games/fan-game-reward/create/new',
    FAN_GAME_DEFAULT_REWARD: '/fan-games/fan-game-reward/:fan_game_id',
    // Female
    TEAMS_FEMALE: '/teams-female',
    TEAM_FEMALE: '/teams-female/:team_id',
    NEW_TEAM_FEMALE: '/teams-female/team/new',
    TEAM_PLAYER_FEMALE: '/teams-female/:team_id/players/:player_id',
    TEAM_NEW_PLAYER_FEMALE: '/teams-female/:team_id/new_player',
    NEW_STAFF_MEMBER_FEMALE: '/teams-female/:team_id/new_staff_member',
    STAFF_MEMBER_FEMALE: '/teams-female/:team_id/staff/:staff_member_id',
    STAFF_FEMALE: '/teams-female/:team_id/staff',
    MATCHES_FEMALE: '/matches-female',
    MATCH_FEMALE: '/matches-female/:match_id',
    NEW_MATCH_FEMALE: '/matches-female/match/new',

    // news
    CREATE_NEWS: '/news/selected/new',
    NEWS: '/news',
    SINGLE_NEWS: `/news/:news_id`,
    //  gastro vouchers
    VOUCHERS_GASTRO: '/gastro-vouchers',
    VOUCHER_GASTRO: '/gastro-vouchers/:voucher_id',
    NEW_GASTRO_VOUCHER: '/gastro-vouchers/voucher/new_voucher',
    NEW_GASTRO_VOUCHER_FROM_FILE: '/gastro-vouchers/voucher/voucher_upload',
    //  all vouchers
    VOUCHERS: '/vouchers',
    VOUCHER: '/vouchers/:voucher_id',
    NEW_VOUCHER: '/vouchers/voucher/new_voucher',
    NEW_VOUCHER_FROM_FILE: '/vouchers/voucher/voucher_upload',
    //
    CONTACTS: '/contacts',
    CONTACT: '/contacts/:contact_id',
    NEW_CONTACT: '/contacts/contact/new',
    LOTTERY_GAMES: '/lottery-games',
    LOTTERY_GAME: '/lottery-games/:lottery_game_id',
    NEW_LOTTERY_GAME: '/lottery-games/lottery-game/new',
    FEEDS: '/feeds',
    FEED: '/feeds/:feed_id',
    NEW_FEED: '/feeds/feed/new',
    MOBILE_APP: '/mobile_app_version',
};

export default Paths;
