import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import Select from '../../components/Select';
import mandatoryFields from '../../configs/match';
import translations from '../../translations/de';
import translateLabel from '../../helpers/translateLabel';
import InputEmpty from '../../components/InputEmpty';

export default function getUserFormInput({
    name,
    register,
    control,
    error,
    disabled,
    index,
    teams,
    onTeamChange,
}) {
    if (name === 'date') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                control={control}
                error={error}
                disabled={disabled}
                withTime
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'home_team_id' || name === 'away_team_id') {
        return (
            teams.length > 0 && (
                <Select
                    key={index}
                    label={translateLabel(name.replace('_id', ''))}
                    name={name}
                    options={teams.map((team) => {
                        return { value: team.id, label: team.title };
                    })}
                    control={control}
                    error={error}
                    disabled={disabled}
                    noOptionsMsg="No teams"
                    isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                    onChangeCallback={(teamID) => onTeamChange(name, teamID)}
                    isClearable
                />
            )
        );
    } else if (
        name === 'one_third_home_score' ||
        name === 'two_thirds_home_score' ||
        name === 'three_thirds_home_score' ||
        name === 'one_third_away_score' ||
        name === 'two_thirds_away_score' ||
        name === 'three_thirds_away_score' ||
        name === 'spectators'
    ) {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="number"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: true,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'dummy') {
        return <InputEmpty key={index} />;
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
