import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import './Notifications.scss';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';
import TabPreview from './TabPreview.js';

const NotificationsTab = ({ store }) => {
    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.PUSH_NOTIFICATIONS}>
                <TabPreview store={store} />
            </Route>
        </Page>
    );
};

export default observer(NotificationsTab);
