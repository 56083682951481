import React, { useEffect, useState, useCallback } from 'react';
import API from '../../api/newsletter';

import Input from '../../components/Input';
import ExportBtn from '../../components/ExportBtn';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Checkbox from '../../components/Checkbox';
import TabSwitcher from './../../components/TabSwitcher/index';

import { FaCheck, FaTimes } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import translations from './../../translations/de';

const Newsletter = () => {
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState(null);
    const [receiveNews, setReceiveNews] = useState({
        [translations.man]: false,
        [translations.woman]: false,
    });

    const debouncedSearch = debounce((value) => {
        setSearch(value);
        setCurrentPage(1);
    }, 500);

    const handleSearchChange = (e) => {
        debouncedSearch(e.target.value);
    };

    const handleTabSwitch = (tabName) => {
        if (tabName !== activeTab) {
            setActiveTab(tabName);
            setSearch('');
            setCurrentPage(1);
        }
    };

    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            const params = {
                search: search || undefined,
                page: currentPage,
                receive_news: receiveNews[activeTab],
                league_acronym: activeTab === translations.woman ? 'PF WL' : 'NL',
            };
            const res = await API.getNewsletterUsers({ params });
            setUsers(res.data.items);
            setTotalPages(res.data.total_pages_count);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [search, activeTab, currentPage, receiveNews]);

    useEffect(() => {
        activeTab && fetchUsers();
    }, [fetchUsers, activeTab]);

    const tableColumns = [
        {
            Header: translations.first_name,
            accessor: 'first_name',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: translations.last_name,
            accessor: 'last_name',
            Cell: ({ value }) => <div>{value}</div>,
        },
        { Header: translations.email, accessor: 'email', Cell: ({ value }) => <div>{value}</div> },
        {
            Header: translations.news,
            accessor: 'receive_news',
            Cell: ({ value }) =>
                value ? (
                    <div>
                        <FaCheck className="confirm" size={20} />
                    </div>
                ) : (
                    <div>
                        <FaTimes className="error" size={20} />
                    </div>
                ),
        },
    ];

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;

        setReceiveNews((prev) => ({
            ...prev,
            [activeTab]: checked,
        }));
    };

    const renderControls = () => (
        <div className="Fan">
            <div className="header-controls">
                <div>
                    <Input placeholder={translations.search} onChange={handleSearchChange} />
                    <Checkbox
                        spanStyle={{ fontSize: '15px', letterSpacing: '1px' }}
                        text="Receive News"
                        name="receive_news"
                        checked={receiveNews[activeTab]}
                        onChange={handleCheckboxChange}
                    />
                </div>
                <ExportBtn
                    label={translations.export_users}
                    fileName="users"
                    apiFunc={API.exportNewsletter}
                />
            </div>
        </div>
    );

    return (
        <TabSwitcher setParentTabName={(e) => handleTabSwitch(e)}>
            <TabSwitcher.Tabs>
                <TabSwitcher.TabPill tabName={translations.man} />
                <TabSwitcher.TabPill tabName={translations.woman} />
            </TabSwitcher.Tabs>

            <TabSwitcher.TabPanel activeWhenTabName={translations.man}>
                {renderControls()}
                <Table
                    noIdColumn={true}
                    isLoading={loading}
                    tablePagination={
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={currentPage}
                                onSelectedPageChange={setCurrentPage}
                            />
                        )
                    }
                    tableName={translations.newsletter_recipients}
                    columns={tableColumns}
                    data={users}
                />
            </TabSwitcher.TabPanel>

            <TabSwitcher.TabPanel activeWhenTabName={translations.woman}>
                {renderControls()}
                <Table
                    noIdColumn={true}
                    isLoading={loading}
                    tablePagination={
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={currentPage}
                                onSelectedPageChange={setCurrentPage}
                            />
                        )
                    }
                    tableName={translations.newsletter_recipients}
                    columns={tableColumns}
                    data={users.map(({ first_name, last_name, email, receive_news }) => {
                        return {
                            first_name,
                            last_name,
                            email,
                            receive_news,
                        };
                    })}
                />
            </TabSwitcher.TabPanel>
        </TabSwitcher>
    );
};

export default Newsletter;
