import React, { forwardRef } from 'react';

import './NotificationCampaignPreview.scss';
import mobileMenu from '../../assets/images/notification_menu.png';
import { IoCloseSharp } from 'react-icons/io5';
import { FaChevronRight } from 'react-icons/fa';
import { formatDate } from '../../helpers/formatDate';

const NotificationCampaignPreview = forwardRef(
    ({ templateNum = 0, data, templateData, onClose }, ref) => {
        const formatCampaignName = (name) => {
            let formattedName = name?.split('_');
            if (formattedName?.[0] !== 'NO') {
                console.log(formattedName?.[0]);
                return formattedName?.[0];
            }
            return formattedName.join(' ');
        };

        const templates = data?.templates ?? [];
        var template = templates[templateNum] ?? templateData ?? {};

        if (data?.title && data?.image && data?.text) {
            template = {
                title: data.title,
                image: data.image,
                text: data.text,
            };
        }
        const { image = '', text = '', title = '' } = template;

        const getValidDate = (delivery_time) => {
            if (delivery_time instanceof Date && !isNaN(delivery_time)) {
                const hours = delivery_time.getHours().toString().padStart(2, '0');
                const minutes = delivery_time.getMinutes().toString().padStart(2, '0');
                return `${hours}:${minutes}`;
            }

            if (typeof delivery_time === 'string' && delivery_time.includes(':')) {
                return delivery_time.slice(0, 5);
            }

            return null;
        };

        return (
            <div className="notification-container" ref={ref}>
                <div className="preview-content">
                    <div className="notif">
                        <div className="header">
                            <p>{formatCampaignName(data?.campaign_type)}</p>
                            <button onClick={onClose}>
                                <IoCloseSharp />
                            </button>
                        </div>
                        <div className="title">
                            <p>{formatCampaignName(data?.campaign_type)}</p>
                            <p>{getValidDate(data?.delivery_time)}</p>
                        </div>
                        <div className="content">
                            <p className="content-date">
                                {formatDate(data?.start_date ?? new Date(), true)}
                            </p>
                            <p className="content-title">{title ?? ''}</p>
                            <img className="content-image" src={image} alt="img" />
                            <p className="content-message">{text ?? ''}</p>
                            {(data?.internal_navigation || data?.data?.internal_navigation) && (
                                <button className="content-button">
                                    <p>Zum Screen</p>
                                    <FaChevronRight />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="mobile-menu">
                        <img src={mobileMenu} alt="mobile" />
                    </div>
                </div>
            </div>
        );
    },
);

export default NotificationCampaignPreview;
