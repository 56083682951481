import './Checkbox.scss';
import React, { forwardRef } from 'react';

const Checkbox = forwardRef(
    ({ text = '', reverseText = false, outerStyle, spanStyle, ...restProps }, ref) => {
        return (
            <div className={`Checkbox ${reverseText ? 'reversed' : ''}`} style={outerStyle}>
                <label className={`Checkbox-Label ${restProps.disabled ? 'disabled' : null}`}>
                    <input type="checkbox" ref={ref} {...restProps}></input>
                    <span></span>
                </label>
                <div className={`Checkbox-Text ${restProps.disabled ? 'disabled' : null}`}>
                    <span style={spanStyle}>{text}</span>
                </div>
            </div>
        );
    },
);

export default Checkbox;
