import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import TabSwitcher from '../../components/TabSwitcher/index';

import Birthday from './NotificationTypes/Birthday';
import Visana from './NotificationTypes/Visana';
import NoShow from './NotificationTypes/NoShow';
import Gameday from './NotificationTypes/Gameday';
import translations from '../../translations/de';

const Notifications = () => {
    const [edit, setEdit] = useState(false);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={() => setEdit((prev) => !prev)}
                    style={{
                        width: '120px',
                        marginRight: '0px',
                        textTransform: 'capitalize',
                        position: 'relative',
                        top: '30px',
                    }}
                >
                    {translations.edit}
                </Button>
            </div>
            <TabSwitcher initialTabName="Birthday" isEditing={edit}>
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName="Birthday" />
                    <TabSwitcher.TabPill tabName="Visana Gameday" />
                    <TabSwitcher.TabPill tabName="No Show" />
                    <TabSwitcher.TabPill tabName="Gameday" />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName="Birthday">
                    <Birthday isEditable={edit} closeEditable={() => setEdit(false)} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Visana Gameday">
                    <Visana isEditable={edit} closeEditable={() => setEdit(false)} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="No Show">
                    <NoShow isEditable={edit} closeEditable={() => setEdit(false)} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Gameday">
                    <Gameday isEditable={edit} closeEditable={() => setEdit(false)} />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    );
};

export default observer(Notifications);
