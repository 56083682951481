import React, { useState, useEffect, useContext, createContext, forwardRef } from 'react';
import './Tabs.scss';

const TabSwitcherContext = createContext({});

const TabPill = forwardRef(({ tabName = '', tabCount = '', tabIcon = null }, ref) => {
    const { activeTabName, setActiveTabName, setParentTabName, isEditing } =
        useContext(TabSwitcherContext);
    const isActive = activeTabName === tabName;

    return (
        <div
            ref={ref}
            className={`Tab ${activeTabName === tabName ? 'selected' : ''}`}
            onClick={() => {
                if (isEditing) return;
                setActiveTabName(tabName);
                setParentTabName && setParentTabName(tabName);
            }}
            style={{
                pointerEvents: isEditing ? 'none' : 'auto',
                backgroundColor: isEditing
                    ? !isActive
                        ? '#f3ebeb'
                        : 'red'
                    : !isActive
                    ? 'unset'
                    : 'red',
            }}
        >
            {tabIcon ? (
                <div className="tab-icon">
                    <img src={tabIcon} alt="tab-icon" />
                </div>
            ) : null}
            <p>
                {' '}
                {tabName}{' '}
                {!!tabCount && <span style={{ marginLeft: '5px' }}>{`(${tabCount})`}</span>}
            </p>
        </div>
    );
});

const Tabs = ({ children, halfWidth = false }) => (
    <div className={`Tabs ${halfWidth ? 'half' : ''}`}>{children}</div>
);

const TabPanel = ({ activeWhenTabName, children }) => {
    const { activeTabName } = useContext(TabSwitcherContext);

    return activeWhenTabName === activeTabName ? <div className="Tab-Panel">{children}</div> : null;
};

const TabSwitcher = (
    {
        initialTabName,
        children,
        setParentTabName = null,
        isEditing = false,
        resetOnInitialTab,
        halfWidth = false,
        setActiveTabForParent,
        onTabChange = null,
        switcherKey = 'default', // New prop to differentiate between different TabSwitchers
    },
    ref,
) => {
    // Modified feature - memorizing active tabs on refresh for both parent and child
    const [activeTabName, setActiveTabName] = useState(() => {
        const storedTabs = JSON.parse(sessionStorage.getItem('activeTabs')) || {};
        return storedTabs[switcherKey] || initialTabName;
    });

    useEffect(() => {
        const tabNames = React.Children.map(children, (child) =>
            child?.props?.activeWhenTabName ? child.props.activeWhenTabName : null,
        ).filter(Boolean);
        if (!tabNames.includes(activeTabName)) {
            setActiveTabName(tabNames[0]);
        }

        resetOnInitialTab && setActiveTabName(resetOnInitialTab);
    }, [children, resetOnInitialTab, activeTabName]);

    useEffect(() => {
        setParentTabName && setParentTabName(activeTabName);
    }, [activeTabName, setParentTabName]);

    useEffect(() => {
        resetOnInitialTab && setActiveTabName(resetOnInitialTab);
    }, [resetOnInitialTab]);

    useEffect(() => {
        const storedTabs = JSON.parse(sessionStorage.getItem('activeTabs')) || {};
        storedTabs[switcherKey] = activeTabName;
        sessionStorage.setItem('activeTabs', JSON.stringify(storedTabs));
    }, [activeTabName, switcherKey]);
    // end of code for the modified feature

    useEffect(() => {
        onTabChange && onTabChange(activeTabName);
    }, [activeTabName, onTabChange]);

    return (
        <TabSwitcherContext.Provider
            value={{ activeTabName, setActiveTabName, setParentTabName, isEditing }}
        >
            {children}
        </TabSwitcherContext.Provider>
    );
};

TabSwitcher.TabPill = TabPill;
TabSwitcher.Tabs = Tabs;
TabSwitcher.TabPanel = TabPanel;

export default TabSwitcher;
