import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import translations from '../../translations/de';
import { formatDate, formatTime } from '../../helpers/formatDate';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import API from '../../api/analytics';
import { CartesianGrid, LineChart, Tooltip, XAxis, YAxis, Line } from 'recharts';
import InputSeason from '../../components/InputSeason/InputSeason';

const MatchStatistics = ({ store }) => {
    const modalRef = null;
    const [modal, setModal] = useModal(modalRef);
    const [data, setData] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);

    const showModal = (id) => {
        //make api call
        const getTicketsInfo = async () => {
            setData([]);
            try {
                const res = await API.getScannetTicketsPerTime(id);
                setData(
                    res.data.map((entry) => {
                        return {
                            count: entry.count,
                            time: formatTime(entry.time),
                        };
                    }),
                );
            } catch (e) {
                console.error(e);
            }
        };
        getTicketsInfo();
        setModal(true);
    };

    const customizedXAxisTick = ({ x, y, payload }) => {
        return (
            <g transform={`translate(${x},${y})`} style={{ fontSize: 12 }}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
                    {payload.value}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload[0]?.value) {
            return (
                <div
                    style={{ padding: '5px 10px', borderRadius: '5px', backgroundColor: '#dae4eb' }}
                >
                    <p style={{ color: 'black' }}>{`Time: ${label}`}</p>
                    <p style={{ color: 'black' }}>{`Count: ${payload[0]?.value}`}</p>
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        selectedSeason && store.getMatchStatistics(selectedSeason);
    }, [store, selectedSeason]);

    return (
        <>
            <div style={{ width: '200px' }}>
                <InputSeason
                    selectedSeason={selectedSeason}
                    setSelectedSeason={setSelectedSeason}
                />
            </div>
            {store.dataLoaded ? (
                <div style={{ width: '100%' }}>
                    {store.matches && store.matches.length !== 0 ? (
                        <Table
                            tableName={translations.match_statistics}
                            columns={[
                                {
                                    Header: 'ID',
                                    accessor: 'id',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: translations.match,
                                    accessor: 'match',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: translations.date,
                                    accessor: 'date',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: translations.created,
                                    accessor: 'accesses_count',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: translations.opened_accesses,
                                    accessor: 'opened_accesses',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: translations.scanned,
                                    accessor: 'scanned_accesses_count',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: translations.graph,
                                    accessor: '',
                                    Cell: ({ row, value }) => (
                                        <div
                                            className="graph-column"
                                            onClick={() => showModal(row.cells[0].value)}
                                        >
                                            view
                                        </div>
                                    ),
                                },
                            ]}
                            data={store.matches.map(
                                ({
                                    id,
                                    away_team,
                                    date,
                                    accesses_count,
                                    opened_accesses,
                                    scanned_accesses_count,
                                }) => {
                                    return {
                                        id,
                                        match: `${!away_team.is_false ? away_team.title : null}`,
                                        date: `${formatDate(date)} - ${formatTime(date)}`,
                                        accesses_count,
                                        opened_accesses,
                                        scanned_accesses_count,
                                    };
                                },
                            )}
                            getRowProps={(row) => {
                                return {
                                    style: {
                                        background: row.values.isEnded ? '#dc393f' : '',
                                        color: row.values.isEnded ? 'white' : '',
                                    },
                                };
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '400px',
                            }}
                        >
                            <div className="piechart-placeholder">
                                No statistics data for selected season.
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                    }}
                >
                    <Loader size="big" theme="dark" />
                </div>
            )}
            {modal && (
                <Modal
                    maxWidth={'900px'}
                    title={'Ticket scans'}
                    ref={modalRef}
                    onClose={() => setModal(false)}
                >
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <LineChart
                            width={900}
                            height={400}
                            data={data}
                            margin={{
                                top: 50,
                                right: 30,
                                left: 70,
                                bottom: 40,
                            }}
                        >
                            <XAxis dataKey={'time'} tick={customizedXAxisTick} interval={1} />
                            <YAxis dataKey={'count'} interval={3} />
                            <Line
                                type="monotone"
                                dataKey="count"
                                stroke="#4472c4"
                                animationDuration={0}
                                dot={false}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip content={CustomTooltip} />
                        </LineChart>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(MatchStatistics);
