import api from './apiLibrary';

const API = {
    getNotifications(params) {
        return api.get('/api/admin/notification', params);
    },
    getNotification(id, data) {
        return api.get(`/api/admin/notification/${id}`, data);
    },
    editNotification(id, data) {
        return api.put(`/api/admin/notification/${id}`, data);
    },
    deleteNotification(id) {
        return api.delete(`/api/admin/notification/${id}`);
    },
    sendNotification(data, params) {
        console.log(params);
        return api.post(`/api/admin/notification`, data, params);
    },
    getRecipients(id, params) {
        return api.get(`/api/admin/notification/${id}/users`, params);
    },
};

export default API;
