import { types, applySnapshot } from 'mobx-state-tree';
import Game from '../fanGamesStore/Game';

const Match = types
    .model('Match', {
        id: types.identifierNumber,
        date: types.maybeNull(types.string, ''),
        home_team: types.frozen({
            id: types.number,
            title: '',
            logo_url: types.optional(types.string, ''),
            is_owner: false,
        }),
        away_team: types.frozen({
            id: types.number,
            title: '',
            logo_url: types.optional(types.string, ''),
            is_owner: false,
        }),
        home_team_id: types.number,
        away_team_id: types.number,
        location: types.optional(types.string, ''),
        fan_games: types.optional(types.array(Game), []),
        phase_name: types.maybeNull(types.string, ''),
        season_id: types.number,
        penalties: types.number,
        spectators: types.number,
        score_home_team: types.number,
        score_away_team: types.number,
        one_third_home_score: types.number,
        one_third_away_score: types.number,
        two_thirds_home_score: types.number,
        two_thirds_away_score: types.number,
        three_thirds_home_score: types.number,
        three_thirds_away_score: types.number,
        //  rest
    })
    .actions((self) => ({
        editMatch(edited) {
            const sanitizedData = {
                ...edited,
                home_team: {
                    ...edited.home_team,
                    logo_url: edited.home_team.logo_url ?? '',
                },
                away_team: {
                    ...edited.away_team,
                    logo_url: edited.away_team.logo_url ?? '',
                },
            };

            applySnapshot(self, { ...self, ...sanitizedData });
        },
    }));

export default Match;
