import { Route } from 'react-router-dom';
import Page from './../../components/Page';
import Teams from './Teams';
import Team from './Team';
import Player from './Player';
import NewTeam from './NewTeam';
import NewPlayer from './NewPlayer';
import NewStaffMember from './NewStaffMember';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

import StaffMember from './StaffMember.js';

const TeamsFemaleTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.TEAMS_FEMALE}>
                <Teams store={store} />
            </Route>
            <Route exact path={Paths.TEAM_FEMALE}>
                <Team store={store} />
            </Route>
            <Route exact path={Paths.TEAM_PLAYER_FEMALE}>
                <Player store={store} />
            </Route>
            <Route exact path={Paths.NEW_TEAM_FEMALE}>
                <NewTeam store={store} />
            </Route>
            <Route exact path={Paths.TEAM_NEW_PLAYER_FEMALE}>
                <NewPlayer store={store} />
            </Route>
            <Route exact path={Paths.NEW_STAFF_MEMBER_FEMALE}>
                <NewStaffMember store={store} />
            </Route>
            <Route exact path={Paths.STAFF_MEMBER_FEMALE}>
                <StaffMember store={store} />
            </Route>
        </Page>
    );
};

export default observer(TeamsFemaleTab);
