import React from 'react';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import Page from './../../components/Page';
import Newsletter from './Newsletter.js';

const NewsletterPage = () => {
    return (
        <Page>
            <Route exact path={Paths.NEWSLETTER}>
                <Newsletter />
            </Route>
        </Page>
    );
};
export default NewsletterPage;
