import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { FaMapMarkerAlt } from 'react-icons/fa';
import translations from '../../translations/de';
import './SearchPlace.scss';
import { Controller } from 'react-hook-form';

const SearchPlaceInput = ({ label, disabled, control, name, isRequired, setCoordinates }) => {
    const handleChange = (e, onChange) => {
        geocodeByAddress(e)
            .then((res) =>
                getLatLng(res[0])
                    .then((res) => {
                        setCoordinates(res.lat, res.lng);
                    })
                    .catch((e) => {}),
            )
            .catch((e) => {
                console.log('GEOCODE ERROR', e);
            });
        onChange(e);
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: isRequired }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PlacesAutocomplete
                    value={value ?? ''}
                    onChange={(e) => handleChange(e, onChange)}
                    debounce={200}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="Input">
                            <div className="label">
                                <span>{label} </span>
                            </div>
                            <input
                                {...getInputProps({ placeholder: 'Search Address...' })}
                                className={[error ? 'error' : ''].join(' ')}
                                type={'text'}
                                disabled={disabled}
                            />
                            <span className="error-msg">
                                {error && <p>{translations.required}</p>}
                            </span>

                            <div className="suggestion-wrapper">
                                {loading ? <div className="tex">loading...</div> : null}
                                {suggestions.map((suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? 'red' : '#fff',
                                    };
                                    return (
                                        <div
                                            key={suggestion.index}
                                            {...getSuggestionItemProps(suggestion, {
                                                style,
                                            })}
                                        >
                                            <div className="tex">
                                                {' '}
                                                <FaMapMarkerAlt style={{ marginRight: '5px' }} />
                                                {suggestion.description}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            )}
        />
    );
};

export default SearchPlaceInput;
