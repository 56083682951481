import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useHistory, useParams } from 'react-router-dom';
import getPlayerFormInput from '../Teams/getPlayerFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import APITeams from '../../api/teams';

const Player = ({ store }) => {
    const history = useHistory();
    const { team_id, player_id } = useParams();
    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        store.getPlayer(team_id, player_id);
        APITeams.getTeams()
            .then((res) => setTeams(res.data))
            .catch((err) => console.log(err));
    }, [store, team_id, player_id]);

    const playerModel = clearObjProps({ ...store.selectedPlayer }, ['id', 'deleted_at']);

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.replace({
                    pathname: `/teams-female/${team_id}`,
                    state: { from: 'player' },
                });
            }
        };
    }, [history, team_id]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        let nowDate = new Date(e.date_of_birth);
        let changedDate = new Date(nowDate.setHours(6));

        store
            .editPlayer({
                ...e,
                date_of_birth: changedDate,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedPlayer ? (
        <Item
            links={[
                {
                    path: Paths.TEAMS,
                    name: translations.teams,
                    onThisLinkClick: () => {
                        store.selectPlayer(undefined);
                    },
                },
                {
                    path: `/teams-female/${store.selectedTeam.id}`,
                    name: store.selectedTeam.title,
                },
            ]}
            name={store.selectedPlayer.first_name + ' ' + store.selectedPlayer.last_name}
            id={`[ player_ID: ${store.selectedPlayer.id} ]`}
            itemModel={playerModel}
            edit={edit}
            onSubmit={onSubmitHandler}
            onEdit={() => setEdit(true)}
            onCancel={() => {
                error && setError(null);
                setEdit(false);
            }}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, error, disabled, index, control }) =>
                getPlayerFormInput({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setResetProp({ [name]: value }),
                    control,
                    team_acronym: store.selectedTeam.totomat_acronym,
                    teams,
                })
            }
            resetProp={resetProp}
        >
            <ItemImage imgSrc={store.selectedPlayer.avatar} isAvatar />
        </Item>
    ) : null;
};

export default observer(Player);
