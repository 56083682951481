import { types, flow, getSnapshot } from 'mobx-state-tree';
import Match from './Match';
import Team from './Team';
import apiTeams from './../../api/teams';
import apiMatches from './../../api/matches';
import apiGames from './../../api/games';
import apiSeasons from './../../api/seasons';
import Game from '../fanGamesStore/Game';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import { formatTime, formatDate } from '../../helpers/formatDate';

const Matches = types
    .model('Matches', {
        matches: types.array(Match),
        teams: types.array(Team),
        selectedMatch: types.maybe(types.reference(Match)),
        selectedGame: types.maybe(types.reference(Game)),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        currentSeasonId: types.maybeNull(types.number),
        dataLoaded: types.optional(types.boolean, false),
    })
    .views((self) => ({
        getMatchTime(id) {
            let match = self.matches.find((match) => match.id === id);
            if (match.date) {
                return formatTime(match.date);
            } else {
                return '';
            }
        },
        getMatchDate(id) {
            let match = self.matches.find((match) => match.id === id);
            if (match.date) {
                return formatDate(match.date);
            } else {
                return 'Not set';
            }
        },
        findNextMatch() {
            let today = new Date();
            let matches = getSnapshot(self.matches);
            let match = matches
                .slice()
                .reverse()
                .find((match) => new Date(match.date) > today);
            if (match) {
                return match.id;
            }
        },
    }))
    .actions((self) => ({
        getMatches: flow(function* getMatches(filter, season, league_acronym) {
            self.error = '';
            self.dataLoaded = false;
            let queryParams = {
                params: {
                    season_id: season ?? undefined,
                    league_acronym: league_acronym ?? undefined,
                },
            };
            try {
                var res = [];
                switch (filter) {
                    case 'all':
                        res = yield apiMatches.getMatches(queryParams);
                        break;
                    case 'scb':
                        res = yield apiMatches.getGameOwnerGames(queryParams);
                        break;
                    default:
                        res = yield apiMatches.getHomeGames(queryParams);
                        break;
                }
                self.dataLoaded = true;
                self.matches = res.data.reverse();
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        updateMatches: flow(function* updateMatches(allSeasons, selectedSeason, filter) {
            self.error = '';
            self.dataLoaded = false;

            var seasonString = allSeasons
                .filter((s) => s.id === selectedSeason)
                .map((s) => s.title.split('/')[1])
                .shift();

            try {
                yield apiMatches.updateMatches(seasonString);
                self.getMatches(filter, selectedSeason);
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        // only for home calendar
        getHomeMatches: flow(function* getMatches() {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield apiMatches.getGameOwnerGames();
                self.dataLoaded = true;
                self.matches = res.data.reverse();
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getTeams: flow(function* getTeams() {
            try {
                const res = yield apiTeams.getTeams();
                self.teams = res.data;
            } catch (error) {
                console.log('error => ' + error);
            }
        }),

        selectMatch(id) {
            self.selectedMatch = id;
        },
        getMatch: flow(function* getMatch(id) {
            self.error = '';
            try {
                const res = yield apiMatches.getMatch(id);
                if (self.selectedMatch) {
                    self.selectedMatch.fan_games = res.data.fan_games;
                } else {
                    self.matches = [];
                    self.matches.push(res.data);
                    self.selectedMatch = res.data.id;
                }
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectGame(id) {
            self.selectedGame = id;
        },
        getGame: flow(function* getGame(match_id, game_id) {
            self.error = '';
            try {
                if (!self.selectedGame) {
                    const match = yield apiMatches.getMatch(match_id);
                    if (!self.selectedMatch) {
                        self.matches = [];
                        self.matches.push(match.data);
                        self.selectedMatch = match.data.id;
                    } else {
                        self.selectedMatch.fan_games = match.data.fan_games;
                    }
                    const game = yield apiGames.getGame(game_id);
                    self.selectedGame = game.data.fan_game_details.id;
                    self.selectedGame.rewards = game.data.fan_game_details.rewards;
                    self.selectedGame.questions = game.data.fan_game_details.questions;
                } else {
                    self.selectedGame.getGameDetails(game_id);
                }
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        addMatch: flow(function* addMatch(newMatch) {
            try {
                const res = yield apiMatches.createMatch({
                    ...newMatch,
                    season_id: self.currentSeasonId,
                });
                self.matches.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editMatch: flow(function* editMatch(edited) {
            try {
                const res = yield apiMatches.editMatch(self.selectedMatch.id, {
                    ...edited,
                    score_home_team: Number(edited.score_home_team),
                    score_away_team: Number(edited.score_away_team),
                    one_third_home_score: Number(edited.one_third_home_score),
                    one_third_away_score: Number(edited.one_third_away_score),
                    two_thirds_home_score: Number(edited.two_thirds_home_score),
                    two_thirds_away_score: Number(edited.two_thirds_away_score),
                    three_thirds_home_score: Number(edited.three_thirds_home_score),
                    three_thirds_away_score: Number(edited.three_thirds_away_score),
                    penalties: Number(edited.penalties),
                    season_id: self.selectedMatch.season_id,
                });
                self.selectedMatch.editMatch(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteMatch: flow(function* deleteMatch(id) {
            try {
                yield apiMatches.deleteMatch(id);
                const index = self.matches.findIndex((m) => m.id === id);
                self.matches.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        createMatchFanGames: flow(function* createMatchFanGames() {
            try {
                yield apiMatches.createFanGames(self.selectedMatch.id);
            } catch (error) {
                throw error;
            }
        }),
        getCurrentSeasonId: flow(function* getCurrentSeasonId() {
            try {
                const res = yield apiSeasons.getCurrnentSeason();
                self.currentSeasonId = res.data && res.data.id;
            } catch (error) {
                console.log('error => ' + error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default Matches;
