import Paths from '../constants/paths';
import drawerSections from '../constants/drawerSections';

const adminAccessPaths = (accountRoles = []) => {
    const routes = [];
    if (accountRoles.find((role) => role === 'ADMIN')) {
        Object.values(Paths).forEach((path) => routes.push(path));
    }
    if (accountRoles.find((role) => role === 'ADMIN_USER')) {
        Object.values(Paths).forEach(
            (path) =>
                path !== Paths.ADMINS &&
                path !== Paths.ADMIN &&
                path !== Paths.NEW_ADMIN &&
                routes.push(path),
        );
    }
    if (accountRoles.find((role) => role === 'ADMIN_BUSINESS')) {
        routes.push(
            Paths.HOME,
            Paths.BUSINESS_PROFILES,
            Paths.BUSINESS_PROFILE,
            Paths.NEW_BUSINESS_PROFILE,
            Paths.BUSINESS_PARTNERS,
            Paths.BUSINESS_PARTNER,
            Paths.NEW_BUSINESS_PARTNER,
            Paths.BUSINESS_EVENTS,
            Paths.BUSINESS_EVENT,
            Paths.NEW_BUSINESS_EVENT,
            Paths.SCANNERS,
            Paths.SCANNER,
            Paths.NEW_SCANNER,
        );
    }
    if (accountRoles.find((role) => role === 'ADMIN_TICKETING')) {
        routes.push(
            Paths.TICKETING_KPI,
            Paths.PASSES,
            Paths.PASS,
            Paths.NEW_PASS,
            Paths.PASSES_F,
            Paths.PASS_F,
            Paths.NEW_PASS_F,
            Paths.E_TICKETS,
            Paths.E_TICKET,
            Paths.NEW_E_TICKET,
            Paths.MATCH_STATISTICS,
            Paths.SECTORS,
            Paths.SECTOR,
            Paths.NEW_SECTOR,
            Paths.SEAT_GROUPS,
            Paths.SEAT_GROUP,
            Paths.NEW_SEAT_GROUP,
            Paths.SEATS,
            Paths.SEAT,
            Paths.NEW_SEAT,
            Paths.SEAT_MAPS,
            Paths.SEAT_MAP,
            Paths.NEW_SEAT_MAP,
        );
    }
    if (accountRoles.find((role) => role === 'ADMIN_FAN_INTERACTION')) {
        routes.push(
            Paths.HOME,
            Paths.MARKETING_KPI,
            Paths.TEAMS,
            Paths.TEAM,
            Paths.NEW_TEAM,
            Paths.TEAM_PLAYER,
            Paths.TEAM_NEW_PLAYER,
            Paths.MATCHES,
            Paths.MATCH,
            Paths.NEW_MATCH,
            Paths.FAN_GAMES,
            Paths.FAN_GAME,
            Paths.MATCH_NEW_FAN_GAME,
            Paths.MATCH_FAN_GAME, // is this needed here?
            Paths.LOTTERY_GAMES,
            Paths.LOTTERY_GAME,
            Paths.NEW_LOTTERY_GAME,
            Paths.VOUCHERS, /// all vouchers - Gutscheine
            Paths.VOUCHER, /// all vouchers - Gutscheine
            Paths.NEW_VOUCHER, /// all vouchers - Gutscheine
            Paths.FEEDS,
            Paths.FEED,
            Paths.NEW_FEED,
            Paths.NOTIFICATIONS,
            Paths.NOTIFICATION,
            Paths.NEW_NOTIFICATION,
            Paths.PUSH_NOTIFICATIONS,
            Paths.SHOP,
            Paths.SHOP_ITEM,
            Paths.SHOP_NEW_ITEM,
            Paths.REWARDS, // still has to be considered
            Paths.REWARD, // still has to be considered
            Paths.NEW_REWARD, // still has to be considered
            Paths.INSTANT_REWARDS, // still has to be considered
            Paths.INSTANT_REWARD, // still has to be considered
            Paths.NEW_INSTANT_REWARD, // still has to be considered
            Paths.SCREENS,
            Paths.SCREEN,
            Paths.NEW_SCREEN,
            Paths.SCREEN_BANNER,
            Paths.SCREEN_NEW_BANNER,
            Paths.NEWS,
        );
    }
    if (accountRoles.find((role) => role === 'ADMIN_GASTRO')) {
        routes.push(
            Paths.HOME,
            Paths.VOUCHERS_GASTRO, /// special type of vouchers? Gutscheine - Gastro
            Paths.VOUCHER, /// special type of vouchers? Gutscheine - Gastro
            Paths.NEW_GASTRO_VOUCHER, /// special type of vouchers? Gutscheine - Gastro
            // Paths.INSTANT_REWARDS, // still has to be considered
            // Paths.INSTANT_REWARD, // still has to be considered
            // Paths.NEW_INSTANT_REWARD, // still has to be considered
        );
    }
    if (accountRoles.find((role) => role === 'ADMIN_SCANNER')) {
        routes.push(Paths.HOME, Paths.SCANNERS, Paths.SCANNER, Paths.NEW_SCANNER);
    }
    if (accountRoles.find((role) => role === 'ADMIN_OPERATOR')) {
        routes.push(Paths.HOME);
    }
    const uniqueRoutes = [...new Set(routes)];
    return uniqueRoutes;
};

const adminAccessSections = (accountRoles = []) => {
    const sections = [];
    if (accountRoles.find((role) => role === 'ADMIN')) {
        Object.values(drawerSections).forEach((section) => sections.push(section));
    }
    if (accountRoles.find((role) => role === 'ADMIN_USER')) {
        Object.values(drawerSections).forEach(
            (section) => section !== 'ADMIN' && sections.push(section),
        );
    }
    if (accountRoles.find((role) => role === 'ADMIN_TICKETING')) {
        sections.push(drawerSections.TICKETING);
    }
    if (accountRoles.find((role) => role === 'ADMIN_FAN_INTERACTION')) {
        sections.push(drawerSections.FAN_INTERACTION);
    }
    if (accountRoles.find((role) => role === 'ADMIN_OPERATOR')) {
        Object.values(drawerSections).forEach((section) => sections.push(section));
    }
    if (accountRoles.find((role) => role === 'ADMIN_BUSINESS')) {
        sections.push(drawerSections.BUSINESS);
    }
    if (accountRoles.find((role) => role === 'ADMIN_GASTRO')) {
        sections.push(drawerSections.GASTRO);
    }
    if (accountRoles.find((role) => role === 'ADMIN_SCANNER')) {
        sections.push(drawerSections.HARDWARE);
    }

    const uniqueSections = [...new Set(sections)];
    return uniqueSections;
};

export { adminAccessPaths, adminAccessSections };
