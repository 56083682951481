import React, { useEffect /*useState*/ } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import { FaPlus, FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
//import Select from '../../components/Select';

const Teams = ({ store }) => {
    const history = useHistory();
    //const [other, setOther] = useState(false);

    useEffect(() => {
        // const params = { other };
        //store.getTeams(params);
        store.getTeams();
    }, [store /*other*/]);

    const handleTeam = (id) => {
        store.selectTeam(id);
        history.push(`/teams/${id}`);
    };

    // const handleOther = (e) => {
    //     if (e === 'other' && other === false) {
    //         setOther(true);
    //     } else if (other) {
    //         setOther(false);
    //     }
    // };

    return (
        <>
            {/* <div className="tab-controls">
                <Select
                    label="League"
                    defaultValue={{ label: 'National', value: 'national' }}
                    options={[
                        { label: 'National', value: 'national' },
                        { label: 'Other', value: 'other' },
                    ]}
                    onChange={(e) => {
                        handleOther(e.value);
                    }}
                />
            </div> */}
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label={translations.team}
                    onClick={() => {
                        store.selectTeam(undefined);
                        history.push(Paths.NEW_TEAM);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>

            <Table
                isLoading={store.isLoading}
                tableName={translations.teams}
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.logo,
                        accessor: 'logo_url',
                        Cell: ({ value, row }) => (
                            <div>
                                <ItemImage imgSrc={value} alt="team" listView={true} />
                            </div>
                        ),
                    },
                    {
                        Header: translations.title,
                        accessor: 'title',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: '',
                        accessor: 'action',
                        Cell: ({ row }) => (
                            <div>
                                <Button
                                    onClick={() => {
                                        handleTeam(row.cells[0].value);
                                    }}
                                    title={translations.show}
                                >
                                    <FaSearch />
                                </Button>
                            </div>
                        ),
                    },
                ]}
                data={store.teams.map(({ id, logo_url, title }) => {
                    return {
                        id,
                        logo_url,
                        title,
                    };
                })}
            />
        </>
    );
};

export default observer(Teams);
