import {
    FaHome,
    FaUsers,
    FaUserShield,
    FaUserCircle,
    FaShoppingBag,
    FaSnowflake,
    FaHockeyPuck,
    FaBell,
    FaMobileAlt,
    FaExpand,
    FaQrcode,
    FaThLarge,
    FaTh,
    FaChair,
    FaSign,
    FaUserFriends,
    FaTrophy,
    FaUserTie,
    FaChartLine,
    FaRegCalendarCheck,
    FaGamepad,
    FaMoneyBillWaveAlt,
    FaAddressBook,
    FaTable,
    FaTicketAlt,
    FaCodeBranch,
    FaMoneyCheck,
    FaDonate,
    FaNewspaper,
    FaRegNewspaper,
} from 'react-icons/fa';
import { MdEditNotifications } from 'react-icons/md';
import { GrAnalytics } from 'react-icons/gr';
import { GiJeweledChalice } from 'react-icons/gi';
import { GrOverview, GrTicket } from 'react-icons/gr';
import { BsDice6Fill } from 'react-icons/bs';
import Paths from '../../constants/paths';
import translations from '../../translations/de';

const home = [
    {
        name: translations.home,
        icon: <FaHome />,
        path: Paths.HOME,
    },
];

const management = [
    {
        name: translations.admin_users,
        icon: <FaUserShield />,
        path: Paths.ADMINS,
    },
    {
        name: translations.mobile_version,
        icon: <FaCodeBranch />,
        path: Paths.MOBILE_APP,
    },
];

const users = [
    {
        name: translations.user,
        icon: <FaUserCircle />,
        path: Paths.FANS,
    },
    {
        name: translations.contacts,
        icon: <FaAddressBook />,
        path: Paths.CONTACTS,
    },
];

const ticketing = [
    {
        name: translations.ticketingKPI,
        icon: <GrTicket />,
        path: Paths.TICKETING_KPI,
    },
    {
        name: translations.passes,
        icon: <FaTicketAlt />,
        path: Paths.PASSES,
    },
    {
        name: translations.passesFriendship,
        icon: <GrOverview />,
        path: Paths.MATCH_OVERVIEW,
    },
    {
        name: translations.accesses,
        icon: <FaQrcode />,
        path: Paths.E_TICKETS,
    },
    {
        name: translations.match_statistics,
        icon: <FaChartLine />,
        path: Paths.MATCH_STATISTICS,
    },
    {
        name: translations.analytics,
        icon: <GrAnalytics />,
        path: Paths.ANALYTICS,
    },
    {
        name: `${translations.sectors} / ${translations.entrances}`,
        icon: <FaThLarge />,
        path: Paths.SECTORS,
    },
    {
        name: translations.seat_groups,
        icon: <FaTh />,
        path: Paths.SEAT_GROUPS,
    },
    {
        name: translations.seats,
        icon: <FaChair />,
        path: Paths.SEATS,
    },
    {
        name: translations.seat_assignments,
        icon: <FaSign />,
        path: Paths.SEAT_MAPS,
    },
    {
        name: translations.seasons,
        icon: <FaSnowflake />,
        path: Paths.SEASONS,
    },
];

const fan_interaction = [
    { name: translations.marketingKPI, icon: <FaDonate />, path: Paths.MARKETING_KPI },
    {
        name: translations.news,
        icon: <FaNewspaper />,
        path: Paths.NEWS,
    },
    {
        name: translations.newsletter,
        icon: <FaRegNewspaper />,
        path: Paths.NEWSLETTER,
    },
    {
        name: `${translations.teams} & ${translations.player}`,
        icon: <FaUsers />,
        path: Paths.TEAMS,
    },
    {
        name: translations.matches,
        icon: <FaHockeyPuck />,
        path: Paths.MATCHES,
    },
    {
        name: translations.fan_games,
        icon: <FaGamepad />,
        path: Paths.FAN_GAMES,
        onClick: () => sessionStorage.removeItem('activeFanGameTab'),
    },
    {
        name: translations.lottery_games,
        icon: <BsDice6Fill />,
        path: Paths.LOTTERY_GAMES,
    },
    {
        name: translations.vouchers_drawer, // old instant rewards
        icon: <FaTrophy />,
        path: Paths.INSTANT_REWARDS,
    },
    {
        name: translations.vouchers_drawer_db,
        icon: <FaMoneyBillWaveAlt />,
        path: Paths.VOUCHERS,
    },
    {
        name: `${translations.feeds}`,
        icon: <FaTable />,
        path: Paths.FEEDS,
    },
    {
        name: translations.notifications,
        icon: <FaBell />,
        path: Paths.NOTIFICATIONS,
    },
    {
        name: translations.push_notifications,
        icon: <MdEditNotifications />,
        path: Paths.PUSH_NOTIFICATIONS,
    },
    {
        name: translations.rewards,
        icon: <GiJeweledChalice />,
        path: Paths.REWARDS,
    },
    {
        name: translations.shop_items,
        icon: <FaShoppingBag />,
        path: Paths.SHOP,
    },
    {
        name: `${translations.app_screens} & ${translations.banners}`,
        icon: <FaMobileAlt />,
        path: Paths.SCREENS,
    },
];

const business = [
    {
        name: 'Events',
        icon: <FaRegCalendarCheck />,
        path: Paths.BUSINESS_EVENTS,
    },
    {
        name: 'Firmen',
        icon: <FaUserTie />,
        path: Paths.BUSINESS_PARTNERS,
    },
    {
        name: 'Personen',
        icon: <FaUserFriends />,
        path: Paths.BUSINESS_PROFILES,
    },
    {
        name: translations.scanner,
        icon: <FaExpand />,
        path: Paths.SCANNERS,
    },
];
const gastro = [
    {
        name: 'Gutscheine-Gastro',
        icon: <FaMoneyCheck />,
        path: Paths.VOUCHERS_GASTRO,
    },
];

const female = [
    {
        name: `${translations.teams} & ${translations.player_female}`,
        icon: <FaUsers />,
        path: Paths.TEAMS_FEMALE,
    },
    {
        name: translations.matches,
        icon: <FaHockeyPuck />,
        path: Paths.MATCHES_FEMALE,
    },
];

export {
    management,
    users,
    ticketing,
    fan_interaction,
    business,
    gastro,
    female,
    home /* baseData, hardware*/,
};
