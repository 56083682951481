import React, { useState, useEffect, useMemo, useRef } from 'react';
import ErrorMessages from '../../../components/ErrorMessages.js/index.js';
import Input from '../../../components/Input/index.js';
import Textarea from '../../../components/Textarea/index.js';
import InputFile from '../../../components/InputFile/index.js';
import Button from '../../../components/Button/index.js';
import Checkbox from '../../../components/Checkbox/index.js';
import Select from '../../../components/Select/index.js';
import DatePicker from '../../../components/DatePicker';
import Loader from '../../../components/Loader/index.js';
import ItemImage from '../../../components/ItemImage/index.js';
import NotificationCampaignPreview from '../../../components/NotificationCampaignPreview/NotificationCampaignPreview.js';
import requsetErrorMessage from '../../../helpers/requsetErrorMessage.js';
import translations from '../../../translations/de.js';
import { useForm } from 'react-hook-form';
import useModal from '../../../customHooks/useModal.js';

import {
    RecipientTypesEnum,
    getRecipientByValue,
} from '../../../constants/notificationRecipients.js';

import { NotificationGroupsEnum } from '../../../constants/notificationGroupsEnum.js';
import { NotificationTypesEnum } from '../../../constants/notificationTypeEnum.js';
import { NotificationCampaignsEnum } from '../../../constants/notificationCampaignsEnum.js';
import { NotificationScreensOptions } from '../../../constants/notificationScreensOptions.js';

import API from '../../../api/notificationsPush.js';

import './FieldsStyle.scss';
import { ISOStoStingTimeOnly } from '../../../helpers/dateToISOStringInObj.js';

const VisanaType = ({ isEditable, closeEditable }) => {
    const [campaignData, setCampaignData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);

    const previewRef = useRef(null);
    const [preview, showPreview] = useModal(false, previewRef);

    const [disabled, setDisabled] = useState(!isEditable);

    const [resetImageUrl, setResetImageUrl] = useState(null);

    const [showExternalLink, setShowExternalLink] = useState(false);

    const formFields = useMemo(
        () => ({
            text: campaignData?.templates[0]?.text,
            image: campaignData?.templates[0]?.image,
            title: campaignData?.templates[0]?.title,
            delivery_time: ISOStoStingTimeOnly(campaignData?.delivery_time),
            internal_navigation: campaignData?.data?.internal_navigation,
            external_navigation: campaignData?.data?.external_navigation,
        }),
        [campaignData],
    );

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        formState: { errors },
        reset,
        control,
    } = useForm({
        defaultValues: formFields,
    });

    const handleClose = () => {
        reset(formFields);
        setResetImageUrl(null);
        closeEditable();
    };

    const submitHandler = (data) => {
        setLoading(true);
        setError(null);

        data.group = NotificationGroupsEnum.ALL_USERS;
        data.notification_type = NotificationTypesEnum.CAMPAIGN_VISANA_GAMEDAY;
        data.campaign_type = NotificationCampaignsEnum.VISANA_GAMEDAY;

        const {
            title,
            text,
            image,
            internal_navigation,
            delivery_time,
            external_navigation,
            ...rest
        } = data;
        const notifData = {
            ...rest,
            delivery_time: delivery_time ? new Date(delivery_time).toISOString() : delivery_time,
            templates: [{ title, text, image }],
            data: {
                ...(showExternalLink
                    ? { external_navigation }
                    : { internal_navigation: internal_navigation }),
            },
        };

        try {
            API.editNotification({ items: [notifData] });
            closeEditable();
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const getVisanaCampaign = async () => {
            try {
                setPageLoader(true);
                const params = { campaign_type: NotificationCampaignsEnum.VISANA_GAMEDAY };
                const res = await API.getCampaign({ params });
                setCampaignData(res.data.items[0]);
            } catch (e) {
                console.error(e);
            } finally {
                setPageLoader(false);
            }
        };
        getVisanaCampaign();
    }, []);

    useEffect(() => {
        setDisabled(!isEditable);
    }, [isEditable]);

    useEffect(() => {
        if (resetImageUrl) {
            reset({ ...watch(), ...resetImageUrl });
        }
    }, [resetImageUrl, reset, watch]);

    useEffect(() => {
        if (campaignData) {
            setTimeout(() => {
                reset(formFields);
            }, 0);
            setResetImageUrl({ image: campaignData.templates[0].image });
        }
    }, [campaignData, formFields, reset]);

    return (
        <div className="New-Notification">
            {!disabled && (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: '10px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button label={translations.cancel} type="button" onClick={handleClose} />
                    <Button
                        loading={loading}
                        label={translations.save}
                        type="submit"
                        onClick={handleSubmit(submitHandler)}
                    />
                </div>
            )}
            {!pageLoader ? (
                <div>
                    <div className="preview-wrapper">
                        <div>
                            <div className="sendTo">
                                <span>
                                    {translations.send_to}:{` `}
                                    {getRecipientByValue(RecipientTypesEnum.ALL_USERS)?.label}
                                </span>
                            </div>
                        </div>
                        <div>
                            <Button
                                theme="gray"
                                label={translations.preview}
                                loading={loading}
                                onClick={() => showPreview(true)}
                            />
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(submitHandler)}>
                        <Input
                            label={translations.title}
                            name="title"
                            placeholder={translations.title}
                            {...register('title', {
                                required: true,
                            })}
                            error={errors.title}
                            disabled={disabled}
                        />
                        <Textarea
                            label={translations.text}
                            name="text"
                            placeholder={translations.message}
                            {...register('text', {
                                required: true,
                            })}
                            error={errors.text}
                            disabled={disabled}
                        />

                        <InputFile
                            label={translations.image}
                            name="image"
                            {...register('image', {
                                required: false,
                            })}
                            error={errors.image}
                            pathToCloudFolder="uploads/notifications"
                            resetValue={(val) => setResetImageUrl({ image: val })}
                            disabled={disabled}
                        />
                        <ItemImage imgSrc={resetImageUrl?.image ?? campaignData?.image} />

                        <div className="timepicker">
                            <DatePicker
                                label={translations.delivery_time}
                                name="delivery_time"
                                timeOnly={true}
                                required={true}
                                defaultValue={new Date()}
                                selectText="Pick Time"
                                control={control}
                                disabled={disabled}
                                error={errors.delivery_time}
                            />
                        </div>
                        <div className="externalLinkWrapper">
                            <div className="checkbox">
                                <Checkbox
                                    name="external_link"
                                    text="External Link"
                                    defaultValue="false"
                                    outerStyle={{ alignSelf: 'center' }}
                                    onChange={() => setShowExternalLink((prev) => !prev)}
                                    disabled={disabled}
                                />
                            </div>

                            {showExternalLink ? (
                                <Input
                                    label={translations.external_link}
                                    name="external_navigation"
                                    placeholder="Type here..."
                                    type="text"
                                    {...register('external_navigation', {
                                        required: false,
                                        validate: {
                                            checkUrl: (value) => {
                                                if (!value) {
                                                    return true;
                                                }
                                                try {
                                                    new URL(value);
                                                    return true;
                                                } catch (error) {
                                                    return 'Invalid URL';
                                                }
                                            },
                                        },
                                    })}
                                    disabled={disabled}
                                    style={{ flex: '1' }}
                                    error={errors.external_navigation}
                                />
                            ) : (
                                <div className="screen-picker">
                                    <Select
                                        label="Screen"
                                        name="internal_navigation"
                                        placeholder="Select Screen"
                                        options={NotificationScreensOptions.map((screen) => ({
                                            value: screen.value,
                                            label: screen.label,
                                        }))}
                                        control={control}
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                        </div>
                    </form>
                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                </div>
            ) : (
                <div className="Page-Loader">
                    <Loader theme="dark" size="big" />{' '}
                </div>
            )}
            {preview && campaignData && (
                <NotificationCampaignPreview
                    data={
                        disabled
                            ? campaignData
                            : {
                                  ...getValues(),
                                  notification_type: NotificationTypesEnum.VISANA_GAMEDAY,
                                  campaign_type: NotificationCampaignsEnum.VISANA_GAMEDAY,
                              }
                    }
                    onClose={() => showPreview(false)}
                />
            )}
        </div>
    );
};

export default VisanaType;
