import api from './apiLibrary';

const API = {
    getNewsletterUsers(params) {
        return api.get('/api/admin/newsletter', params);
    },
    exportNewsletter() {
        return api.get(`/api/admin/newsletter/export`);
    },
};

export default API;
