import React, { useState, useEffect, useMemo, useRef } from 'react';
import ErrorMessages from '../../../components/ErrorMessages.js/index.js';
import Input from '../../../components/Input/index.js';
import Textarea from '../../../components/Textarea/index.js';
import InputFile from '../../../components/InputFile/index.js';
import Button from '../../../components/Button/index.js';
import DatePicker from '../../../components/DatePicker';
import Checkbox from '../../../components/Checkbox/index.js';
import Loader from '../../../components/Loader/index.js';
import ItemImage from '../../../components/ItemImage/index.js';
import NotificationCampaignPreview from './../../../components/NotificationCampaignPreview/NotificationCampaignPreview';
import useModal from '../../../customHooks/useModal.js';

import requsetErrorMessage from '../../../helpers/requsetErrorMessage.js';
import { useForm } from 'react-hook-form';
import translations from '../../../translations/de.js';

import {
    RecipientTypesEnum,
    getRecipientByValue,
} from '../../../constants/notificationRecipients.js';

import './FieldsStyle.scss';
import API from '../../../api/notificationsPush.js';
import { NotificationCampaignsEnum } from '../../../constants/notificationCampaignsEnum.js';
import { ISOStoStingTimeOnly } from '../../../helpers/dateToISOStringInObj.js';
import { NotificationGroupsEnum } from '../../../constants/notificationGroupsEnum.js';
import { NotificationTypesEnum } from '../../../constants/notificationTypeEnum.js';

const Gameday = ({ isEditable, closeEditable }) => {
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(!isEditable);
    const [loading, setLoading] = useState(false);

    const [homeSeasonData, setHomeSeasonData] = useState([]);
    const [homeNonSeasonData, setHomeNonSeasonData] = useState([]);
    const [awayData, setAwayData] = useState([]);

    const [pageLoader, setPageLoader] = useState(false);

    const previewHomeSeasonRef = useRef(null);
    const previewHomeNonSeasonRef = useRef(null);
    const previewAwayRef = useRef(null);

    const [previewHomeSeason, showPreviewHomeSeason] = useModal(
        false,
        previewHomeSeasonRef,
        false,
        false,
    );
    const [previewHomeNonSeason, showPreviewHomeNonSeason] = useModal(
        false,
        previewHomeNonSeasonRef,
        false,
        false,
    );

    const [previewAway, showPreviewAway] = useModal(false, previewAwayRef, false, false);

    const [templateNum, setTemplateNum] = useState(null);

    const [resetHomeGameSeasonImageUrl_1, setResetHomeGameSeasonImageUrl_1] = useState(
        homeSeasonData?.templates?.[0]?.image,
    );
    const [resetHomeGameSeasonImageUrl_2, setResetHomeGameSeasonImageUrl_2] = useState(
        homeSeasonData?.templates?.[1]?.image,
    );
    const [resetHomeGameSeasonImageUrl_3, setResetHomeGameSeasonImageUrl_3] = useState(
        homeSeasonData?.templates?.[2]?.image,
    );

    const [resetHomeGameNonSeasonImageUrl_1, setResetHomeGameNonSeasonImageUrl_1] = useState(
        homeNonSeasonData?.templates?.[0]?.image,
    );
    const [resetHomeGameNonSeasonImageUrl_2, setResetHomeGameNonSeasonImageUrl_2] = useState(
        homeNonSeasonData?.templates?.[1]?.image,
    );
    const [resetHomeGameNonSeasonImageUrl_3, setResetHomeGameNonSeasonImageUrl_3] = useState(
        homeNonSeasonData?.templates?.[2]?.image,
    );

    const [resetAwayGameImageUrl_1, setResetAwayGameSeasonUrl_1] = useState(
        awayData?.templates?.[0]?.image,
    );
    const [resetAwayGameImageUrl_2, setResetAwayGameSeasonUrl_2] = useState(
        awayData?.templates?.[1]?.image,
    );
    const [resetAwayGameImageUrl_3, setResetAwayGameSeasonUrl_3] = useState(
        awayData?.templates?.[2]?.image,
    );

    const formFields = useMemo(
        () => ({
            home_season_title_1: homeSeasonData?.templates?.[0]?.title ?? '',
            home_season_title_2: homeSeasonData?.templates?.[1]?.title,
            home_season_title_3: homeSeasonData?.templates?.[2]?.title,
            home_season_text_1: homeSeasonData?.templates?.[0]?.text,
            home_season_text_2: homeSeasonData?.templates?.[1]?.text,
            home_season_text_3: homeSeasonData?.templates?.[2]?.text,
            home_season_image_1: homeSeasonData?.templates?.[0]?.image,
            home_season_image_2: homeSeasonData?.templates?.[1]?.image,
            home_season_image_3: homeSeasonData?.templates?.[2]?.image,
            home_season_delivery_time: ISOStoStingTimeOnly(homeSeasonData?.delivery_time),
            home_season_start_date: homeSeasonData?.start_date,
            home_season_paused: homeSeasonData?.paused,
            home_season_external_navigation: homeSeasonData?.external_navigation,
            //
            home_nonseason_title_1: homeNonSeasonData?.templates?.[0]?.title,
            home_nonseason_title_2: homeNonSeasonData?.templates?.[1]?.title,
            home_nonseason_title_3: homeNonSeasonData?.templates?.[2]?.title,
            home_nonseason_text_1: homeNonSeasonData?.templates?.[0]?.text,
            home_nonseason_text_2: homeNonSeasonData?.templates?.[1]?.text,
            home_nonseason_text_3: homeNonSeasonData?.templates?.[2]?.text,
            home_nonseason_image_1: homeNonSeasonData?.templates?.[0]?.image,
            home_nonseason_image_2: homeNonSeasonData?.templates?.[1]?.image,
            home_nonseason_image_3: homeNonSeasonData?.templates?.[2]?.image,
            home_nonseason_delivery_time: ISOStoStingTimeOnly(homeNonSeasonData?.delivery_time),
            home_nonseason_start_date: homeNonSeasonData?.start_date,
            home_nonseason_paused: homeNonSeasonData?.paused,
            home_nonseason_external_navigation: homeNonSeasonData?.external_navigation,
            //
            away_title_1: awayData?.templates?.[0]?.title,
            away_title_2: awayData?.templates?.[1]?.title,
            away_title_3: awayData?.templates?.[2]?.title,
            away_text_1: awayData?.templates?.[0]?.text,
            away_text_2: awayData?.templates?.[1]?.text,
            away_text_3: awayData?.templates?.[2]?.text,
            away_image_1: awayData?.templates?.[0]?.image,
            away_image_2: awayData?.templates?.[1]?.image,
            away_image_3: awayData?.templates?.[2]?.image,
            away_delivery_time: ISOStoStingTimeOnly(awayData?.delivery_time),
            away_start_date: awayData?.start_date,
            away_paused: awayData?.paused,
            away_external_navigation: awayData?.external_navigation,
        }),
        [homeSeasonData, homeNonSeasonData, awayData],
    );

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        formState: { errors },
        reset,
        control,
    } = useForm({
        defaultValues: formFields,
    });

    const submitHandler = (data) => {
        setLoading(true);
        setError(null);

        // I - separating data
        let data_1 = {
            ...removePrefix('home_season_', data),
            group: NotificationGroupsEnum.SEASON_TICKET_HOLDERS,
            notification_type: NotificationTypesEnum.CAMPAIGN_GAMEDAY,
            campaign_type: NotificationCampaignsEnum.GAMEDAY,
        };

        let data_2 = {
            ...removePrefix('home_nonseason_', data),
            group: NotificationGroupsEnum.NON_SEASON_TICKET_HOLDERS,
            notification_type: NotificationTypesEnum.CAMPAIGN_GAMEDAY,
            campaign_type: NotificationCampaignsEnum.GAMEDAY,
        };

        let data_3 = {
            ...removePrefix('away_', data),
            group: NotificationGroupsEnum.ALL_USERS,
            notification_type: NotificationTypesEnum.CAMPAIGN_GAMEDAY,
            campaign_type: NotificationCampaignsEnum.GAMEDAY,
        };

        // II - Separating data
        const {
            start_date: homeSeasonStartDate,
            delivery_time: homeSeasonDeliveryTime,
            title_1: homeSeasonTitle1,
            image_1: homeSeasonImage1,
            text_1: homeSeasonText1,
            title_2: homeSeasonTitle2,
            image_2: homeSeasonImage2,
            text_2: homeSeasonText2,
            title_3: homeSeasonTitle3,
            image_3: homeSeasonImage3,
            text_3: homeSeasonText3,
            external_navigation: homeSeasonExternalNav,
            ...homeSeasonOther
        } = data_1;
        const {
            start_date: homeNonSeasonStartDate,
            delivery_time: homeNonSeasonDeliveryTime,
            title_1: homeNonSeasonTitle1,
            image_1: homeNonSeasonImage1,
            text_1: homeNonSeasonText1,
            title_2: homeNonSeasonTitle2,
            image_2: homeNonSeasonImage2,
            text_2: homeNonSeasonText2,
            title_3: homeNonSeasonTitle3,
            image_3: homeNonSeasonImage3,
            text_3: homeNonSeasonText3,
            external_navigation: homeNonSeasonExternalNav,
            ...homeNonSeasonOther
        } = data_2;
        const {
            start_date: awayStartDate,
            delivery_time: awayDeliveryTime,
            title_1: awayTitle1,
            title_2: awayTitle2,
            title_3: awayTitle3,
            text_1: awayText1,
            text_2: awayText2,
            text_3: awayText3,
            image_1: awayImage1,
            image_2: awayImage2,
            image_3: awayImage3,
            external_navigation: awayExternalNav,
            ...awayOther
        } = data_3;
        // III - Formatting data
        const data_1_formatted = {
            ...homeSeasonOther,
            start_date: homeSeasonStartDate ? new Date(homeSeasonStartDate).toISOString() : null,
            delivery_time: homeSeasonDeliveryTime ? new Date(homeSeasonDeliveryTime) : null,
            data: { external_navigation: homeSeasonExternalNav },
            templates: [
                {
                    title: homeSeasonTitle1,
                    image: homeSeasonImage1,
                    text: homeSeasonText1,
                },
                {
                    title: homeSeasonTitle2,
                    text: homeSeasonText2,
                    image: homeSeasonImage2,
                },
                {
                    title: homeSeasonTitle3,
                    text: homeSeasonText3,
                    image: homeSeasonImage3,
                },
            ],
        };

        const data_2_formatted = {
            ...homeNonSeasonOther,
            start_date: homeNonSeasonStartDate,
            delivery_time: homeNonSeasonDeliveryTime,
            data: { external_navigation: homeNonSeasonExternalNav },
            templates: [
                {
                    title: homeNonSeasonTitle1,
                    image: homeNonSeasonImage1,
                    text: homeNonSeasonText1,
                },
                {
                    title: homeNonSeasonTitle2,
                    text: homeNonSeasonText2,
                    image: homeNonSeasonImage2,
                },
                {
                    title: homeNonSeasonTitle3,
                    text: homeNonSeasonText3,
                    image: homeNonSeasonImage3,
                },
            ],
        };

        const data_3_formatted = {
            ...awayOther,
            start_date: awayStartDate,
            delivery_time: awayDeliveryTime,
            data: { external_navigation: awayExternalNav },
            templates: [
                {
                    title: awayTitle1,
                    text: awayText1,
                    image: awayImage1,
                },
                {
                    title: awayTitle2,
                    text: awayText2,
                    image: awayImage2,
                },
                {
                    title: awayTitle3,
                    text: awayText3,
                    image: awayImage3,
                },
            ],
        };

        // IV - final combining
        const reqBody = {
            items: [{ ...data_1_formatted }, { ...data_2_formatted }, { ...data_3_formatted }],
        };
        try {
            API.editNotification(reqBody);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
            closeEditable();
        }
    };

    const removePrefix = (prefix, sourceData) => {
        let newData = {};
        Object.keys(sourceData).forEach((key) => {
            if (key.startsWith(prefix)) {
                const newKey = key.replace(prefix, '');
                newData[newKey] = sourceData[key];
            }
        });
        return newData;
    };

    function transformData(data) {
        const templates = [];

        for (let i = 1; i <= 3; i++) {
            templates.push({
                title: data[`title_${i}`],
                text: data[`text_${i}`],
                image: data[`image_${i}`],
            });
        }
        return {
            templates,
            delivery_time: new Date(data?.delivery_time).toISOString().slice(11, 19),
            start_date: data.start_date,
            paused: data.paused,
            notification_type: NotificationTypesEnum.CAMPAIGN_GAMEDAY,
            campaign_type: NotificationCampaignsEnum.GAMEDAY,
        };
    }

    const handleClose = () => {
        reset(formFields);
        setResetHomeGameSeasonImageUrl_1(null);
        setResetHomeGameSeasonImageUrl_2(null);
        setResetHomeGameSeasonImageUrl_3(null);

        setResetHomeGameNonSeasonImageUrl_1(null);
        setResetHomeGameNonSeasonImageUrl_2(null);
        setResetHomeGameNonSeasonImageUrl_3(null);

        setResetAwayGameSeasonUrl_1(null);
        setResetAwayGameSeasonUrl_2(null);
        setResetAwayGameSeasonUrl_3(null);

        closeEditable();
    };

    useEffect(() => {
        const getCampaign = async () => {
            try {
                setPageLoader(true);
                const res = await API.getCampaign({
                    params: {
                        campaign_type: NotificationCampaignsEnum.GAMEDAY,
                    },
                });
                setHomeSeasonData(res?.data?.items?.[0]);
                setHomeNonSeasonData(res?.data?.items?.[1]);
                setAwayData(res?.data?.items?.[2]);
            } catch (e) {
                console.log(e);
            } finally {
                setPageLoader(false);
            }
        };
        getCampaign();
    }, []);

    useEffect(() => {
        if (homeSeasonData && homeNonSeasonData && awayData) {
            setTimeout(() => {
                reset(formFields);
            }, 0);
        }
    }, [homeSeasonData, homeNonSeasonData, awayData, reset, formFields]);

    useEffect(() => {
        if (resetHomeGameSeasonImageUrl_1) {
            reset({ ...watch(), ...resetHomeGameSeasonImageUrl_1 });
        }
    }, [resetHomeGameSeasonImageUrl_1, reset, watch]);

    useEffect(() => {
        if (resetHomeGameSeasonImageUrl_2) {
            reset({ ...watch(), ...resetHomeGameSeasonImageUrl_2 });
        }
    }, [resetHomeGameSeasonImageUrl_2, reset, watch]);

    useEffect(() => {
        if (resetHomeGameSeasonImageUrl_3) {
            reset({ ...watch(), ...resetHomeGameSeasonImageUrl_3 });
        }
    }, [resetHomeGameSeasonImageUrl_3, reset, watch]);

    useEffect(() => {
        if (resetHomeGameNonSeasonImageUrl_1) {
            reset({ ...watch(), ...resetHomeGameNonSeasonImageUrl_1 });
        }
    }, [resetHomeGameNonSeasonImageUrl_1, reset, watch]);

    useEffect(() => {
        if (resetHomeGameNonSeasonImageUrl_2) {
            reset({ ...watch(), ...resetHomeGameNonSeasonImageUrl_2 });
        }
    }, [resetHomeGameNonSeasonImageUrl_2, reset, watch]);

    useEffect(() => {
        if (resetHomeGameNonSeasonImageUrl_3) {
            reset({ ...watch(), ...resetHomeGameNonSeasonImageUrl_3 });
        }
    }, [resetHomeGameNonSeasonImageUrl_3, reset, watch]);

    useEffect(() => {
        if (resetAwayGameImageUrl_1) {
            reset({ ...watch(), ...resetAwayGameImageUrl_1 });
        }
    }, [resetAwayGameImageUrl_1, reset, watch]);

    useEffect(() => {
        if (resetAwayGameImageUrl_2) {
            reset({ ...watch(), ...resetAwayGameImageUrl_2 });
        }
    }, [resetAwayGameImageUrl_2, reset, watch]);

    useEffect(() => {
        if (resetAwayGameImageUrl_3) {
            reset({ ...watch(), ...resetAwayGameImageUrl_3 });
        }
    }, [resetAwayGameImageUrl_3, reset, watch]);

    useEffect(() => {
        setDisabled(!isEditable);
    }, [isEditable]);

    return (
        <div className="New-Notification">
            <div>
                {!disabled && (
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            gap: '10px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            label={translations.cancel}
                            type="button"
                            onClick={() => handleClose()}
                        />
                        <Button
                            loading={loading}
                            label={translations.save}
                            type="submit"
                            onClick={handleSubmit(submitHandler)}
                        />
                    </div>
                )}
                {!pageLoader ? (
                    <div>
                        <h3 style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
                            {translations.homegames}
                        </h3>
                        <div className="preview-wrapper">
                            <div className="sendTo">
                                <span>
                                    {translations.send_to}:{` `}
                                    {
                                        getRecipientByValue(
                                            RecipientTypesEnum.SEASON_TICKET_HOLDERS,
                                        )?.label
                                    }
                                </span>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(submitHandler)}>
                            {/* Homegame I */}
                            <>
                                <div className="group-wrapper">
                                    <div className="games-wrapper">
                                        {/* First Game */}
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(0);
                                                    showPreviewHomeSeason(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="home_season_title_1"
                                            {...register('home_season_title_1', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_season_title_1}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="home_season_text_1"
                                            {...register('home_season_text_1', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_season_text_1}
                                        />

                                        <InputFile
                                            label={translations.image}
                                            name="home_season_image_1"
                                            {...register('home_season_image_1', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_season_image_1}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetHomeGameSeasonImageUrl_1({
                                                    home_season_image_1: val,
                                                })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetHomeGameSeasonImageUrl_1?.home_season_image_1 ??
                                                homeSeasonData?.templates?.[0]?.image
                                            }
                                        />
                                    </div>

                                    <div className="games-wrapper">
                                        {/* Second Game */}
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(1);
                                                    showPreviewHomeSeason(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="home_season_title_2"
                                            disabled={disabled}
                                            {...register('home_season_title_2', {
                                                required: true,
                                            })}
                                            error={errors.home_season_title_2}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="home_season_text_2"
                                            disabled={disabled}
                                            {...register('home_season_text_2', {
                                                required: true,
                                            })}
                                            error={errors.home_season_text_2}
                                        />

                                        <InputFile
                                            label={translations.image}
                                            name="home_season_image_2"
                                            disabled={disabled}
                                            {...register('home_season_image_2', {
                                                required: false,
                                            })}
                                            error={errors.home_season_image_2}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetHomeGameSeasonImageUrl_2({
                                                    home_season_image_2: val,
                                                })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetHomeGameSeasonImageUrl_2?.home_season_image_2 ??
                                                homeSeasonData?.templates?.[1]?.image
                                            }
                                        />
                                    </div>
                                    <div className="games-wrapper">
                                        {/* Third Game */}
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(2);
                                                    showPreviewHomeSeason(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="home_season_title_3"
                                            disabled={disabled}
                                            {...register('home_season_title_3', {
                                                required: true,
                                            })}
                                            error={errors.home_season_title_3}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="home_season_text_3"
                                            disabled={disabled}
                                            {...register('home_season_text_3', {
                                                required: true,
                                            })}
                                            error={errors.home_season_text_3}
                                        />

                                        <InputFile
                                            label={translations.image}
                                            name="home_season_image_3"
                                            disabled={disabled}
                                            {...register('home_season_image_3', {
                                                required: false,
                                            })}
                                            error={errors.home_season_image_3}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetHomeGameSeasonImageUrl_3({
                                                    home_season_image_3: val,
                                                })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetHomeGameSeasonImageUrl_3?.home_season_image_3 ??
                                                homeSeasonData?.templates?.[2]?.image
                                            }
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '30px',
                                        width: '820px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="timepicker">
                                        <DatePicker
                                            timeOnly={true}
                                            label={translations.delivery_time}
                                            name="home_season_delivery_time"
                                            defaultValue={new Date()}
                                            selectText="Select a date..."
                                            control={control}
                                            disabled={disabled}
                                            error={errors.home_season_delivery_time}
                                        />
                                    </div>
                                    <div className="datepicker">
                                        <DatePicker
                                            label={translations.starting_date}
                                            name="home_season_start_date"
                                            defaultValue={new Date()}
                                            control={control}
                                            selectText="Select a date..."
                                            disabled={disabled}
                                            error={errors.home_season_start_date}
                                        />
                                    </div>
                                    <div>
                                        <Checkbox
                                            text={translations.paused}
                                            name={'home_season_paused'}
                                            {...register('home_season_paused', { required: false })}
                                            disabled={disabled}
                                            error={errors.home_season_paused}
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '465px' }}>
                                    <Input
                                        label={translations.link_on_the_button}
                                        placeholder={translations.go_to_season_ticket}
                                        name="home_season_external_navigation"
                                        {...register('home_season_external_navigation', {
                                            required: false,
                                            validate: {
                                                checkUrl: (value) => {
                                                    if (!value) {
                                                        return true;
                                                    }
                                                    try {
                                                        new URL(value);
                                                        return true;
                                                    } catch (error) {
                                                        return 'Invalid URL';
                                                    }
                                                },
                                            },
                                        })}
                                        disabled={disabled}
                                        error={errors.home_season_external_navigation}
                                    />
                                </div>
                            </>
                            <>
                                {/* Homegame II */}
                                <h3 style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
                                    {translations.homegames}
                                </h3>
                                <div className="preview-wrapper">
                                    <div className="sendTo">
                                        <span>
                                            {translations.send_to}:{` `}
                                            {
                                                getRecipientByValue(
                                                    RecipientTypesEnum.NON_SEASON_TICKET_HOLDERS,
                                                )?.label
                                            }
                                        </span>
                                    </div>
                                </div>
                                {/* First Game */}
                                <div className="group-wrapper">
                                    <div className="games-wrapper">
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(0);
                                                    showPreviewHomeNonSeason(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="home_nonseason_title_1"
                                            disabled={disabled}
                                            {...register('home_nonseason_title_1', {
                                                required: true,
                                            })}
                                            error={errors.home_nonseason_title_1}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="home_nonseason_text_1"
                                            {...register('home_nonseason_text_1', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_nonseason_text_1}
                                        />

                                        <InputFile
                                            label={translations.image}
                                            name="home_nonseason_image_1"
                                            {...register('home_nonseason_image_1', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_nonseason_image_1}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetHomeGameNonSeasonImageUrl_1({
                                                    home_nonseason_image_1: val,
                                                })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetHomeGameNonSeasonImageUrl_1?.home_nonseason_image_1 ??
                                                homeNonSeasonData?.templates?.[0]?.image
                                            }
                                        />
                                    </div>
                                    <div className="games-wrapper">
                                        {/* Second Game */}
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(1);
                                                    showPreviewHomeNonSeason(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="home_nonseason_title_2"
                                            {...register('home_nonseason_title_2', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_nonseason_title_2}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="home_nonseason_text_2"
                                            {...register('home_nonseason_text_2', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.text}
                                        />

                                        <InputFile
                                            label={translations.image}
                                            name="home_nonseason_image_2"
                                            {...register('home_nonseason_image_2', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.image}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetHomeGameNonSeasonImageUrl_2({
                                                    home_nonseason_image_2: val,
                                                })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetHomeGameNonSeasonImageUrl_2?.home_nonseason_image_2 ??
                                                homeNonSeasonData?.templates?.[1]?.image
                                            }
                                        />
                                    </div>
                                    <div className="games-wrapper">
                                        {/* Third Game */}
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(2);
                                                    showPreviewHomeNonSeason(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="home_nonseason_title_3"
                                            disabled={disabled}
                                            {...register('home_nonseason_title_3', {
                                                required: true,
                                            })}
                                            error={errors.home_nonseason_title_3}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="home_nonseason_text_3"
                                            disabled={disabled}
                                            {...register('home_nonseason_text_3', {
                                                required: true,
                                            })}
                                            error={errors.home_nonseason_text_3}
                                        />
                                        <InputFile
                                            label={translations.image}
                                            name="home_nonseason_image_3"
                                            {...register('home_nonseason_image_3', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_nonseason_image_3}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetHomeGameNonSeasonImageUrl_3({
                                                    home_nonseason_image_3: val,
                                                })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetHomeGameNonSeasonImageUrl_3?.home_nonseason_image_3 ??
                                                homeNonSeasonData?.templates?.[2]?.image
                                            }
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '30px',
                                        width: '820px',
                                        alignItems: 'center',
                                    }}
                                >
                                    {' '}
                                    <div className="timepicker">
                                        <DatePicker
                                            timeOnly={true}
                                            label={translations.delivery_time}
                                            name="home_nonseason_delivery_time"
                                            defaultValue={new Date()}
                                            selectText="Select a date..."
                                            control={control}
                                            disabled={disabled}
                                            error={errors.home_nonseason_delivery_time}
                                        />
                                    </div>
                                    <div className="datepicker">
                                        <DatePicker
                                            label={translations.starting_date}
                                            name="home_nonseason_start_date"
                                            defaultValue={new Date()}
                                            control={control}
                                            selectText="Select a date..."
                                            disabled={disabled}
                                            error={errors.home_nonseason_start_date}
                                        />
                                    </div>
                                    <div>
                                        <Checkbox
                                            text={translations.paused}
                                            name={'home_nonseason_paused'}
                                            {...register('home_nonseason_paused', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.home_nonseason_paused}
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '465px' }}>
                                    <Input
                                        label={translations.link_on_the_button}
                                        placeholder={translations.go_to_season_ticket}
                                        name="home_nonseason_external_navigation"
                                        {...register('home_nonseason_external_navigation', {
                                            required: false,
                                            validate: {
                                                checkUrl: (value) => {
                                                    if (!value) {
                                                        return true;
                                                    }
                                                    try {
                                                        new URL(value);
                                                        return true;
                                                    } catch (error) {
                                                        return 'Invalid URL';
                                                    }
                                                },
                                            },
                                        })}
                                        disabled={disabled}
                                        error={errors.home_nonseason_external_navigation}
                                    />
                                </div>
                            </>
                            {/** Away Game */}
                            <>
                                <h3 style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
                                    {translations.away_games}
                                </h3>
                                <div className="preview-wrapper">
                                    <div className="sendTo">
                                        <span>
                                            {translations.send_to}:{` `}
                                            {
                                                getRecipientByValue(
                                                    RecipientTypesEnum.SEASON_TICKET_HOLDERS,
                                                )?.label
                                            }
                                        </span>
                                    </div>
                                </div>
                                {/* First Game */}
                                <div className="group-wrapper">
                                    <div className="games-wrapper">
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(0);
                                                    showPreviewAway(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="away_title_1"
                                            {...register('away_title_1', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_title_1}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="away_text_1"
                                            {...register('away_text_1', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_text_1}
                                        />
                                        <InputFile
                                            label={translations.image}
                                            name="away_image_1"
                                            {...register('away_image_1', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_image_1}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetAwayGameSeasonUrl_1({ away_image_1: val })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetAwayGameImageUrl_1?.away_image_1 ??
                                                awayData?.templates?.[0]?.image
                                            }
                                        />
                                    </div>
                                    <div className="games-wrapper">
                                        {/* Second Game */}
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(1);
                                                    showPreviewAway(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="away_title_2"
                                            {...register('away_title_2', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_title_2}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            disabled={disabled}
                                            name="away_text_2"
                                            {...register('away_text_2', {
                                                required: true,
                                            })}
                                            error={errors.away_text_2}
                                        />

                                        <InputFile
                                            label={translations.image}
                                            name="away_image_2"
                                            {...register('away_image_2', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_image_2}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetAwayGameSeasonUrl_2({ away_image_2: val })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetAwayGameImageUrl_2?.away_image_2 ??
                                                awayData?.templates?.[1]?.image
                                            }
                                        />
                                    </div>
                                    <div className="games-wrapper">
                                        {/* Third Game */}
                                        <div className="gameday-subtitle-wrapper">
                                            <Button
                                                type="button"
                                                theme="gray"
                                                label={translations.preview}
                                                loading={loading}
                                                onClick={() => {
                                                    setTemplateNum(2);
                                                    showPreviewAway(true);
                                                }}
                                            />
                                        </div>
                                        <Input
                                            label={translations.title}
                                            placeholder={translations.title}
                                            name="away_title_3"
                                            {...register('away_title_3', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_title_3}
                                        />
                                        <Textarea
                                            label={translations.text}
                                            placeholder={translations.message}
                                            name="away_text_3"
                                            {...register('away_text_3', {
                                                required: true,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_text_3}
                                        />

                                        <InputFile
                                            label={translations.image}
                                            name="image"
                                            {...register('away_image_3', {
                                                required: false,
                                            })}
                                            disabled={disabled}
                                            error={errors.away_image_3}
                                            pathToCloudFolder="uploads/notifications"
                                            resetValue={(val) =>
                                                setResetAwayGameSeasonUrl_3({ away_image_3: val })
                                            }
                                        />
                                        <ItemImage
                                            imgSrc={
                                                resetAwayGameImageUrl_3?.away_image_3 ??
                                                awayData?.templates?.[2]?.image
                                            }
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '30px',
                                        width: '820px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="timepicker">
                                        <DatePicker
                                            timeOnly={true}
                                            label={translations.delivery_time}
                                            name="away_delivery_time"
                                            defaultValue={new Date()}
                                            selectText="Select a date..."
                                            control={control}
                                            disabled={disabled}
                                            error={errors.away_delivery_time}
                                        />
                                    </div>
                                    <div className="datepicker">
                                        <DatePicker
                                            label={translations.starting_date}
                                            name="away_start_date"
                                            defaultValue={new Date()}
                                            control={control}
                                            selectText="Select a date..."
                                            disabled={disabled}
                                            error={errors.away_start_date}
                                        />
                                    </div>
                                    <div>
                                        <Checkbox
                                            text={translations.paused}
                                            name={'away_paused'}
                                            {...register('away_paused', { required: false })}
                                            disabled={disabled}
                                            error={errors.away_paused}
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '465px' }}>
                                    <Input
                                        label={translations.link_on_the_button}
                                        placeholder={translations.go_to_season_ticket}
                                        name="away_external_navigation"
                                        {...register('away_external_navigation', {
                                            required: false,
                                            validate: {
                                                checkUrl: (value) => {
                                                    if (!value) {
                                                        return true;
                                                    }
                                                    try {
                                                        new URL(value);
                                                        return true;
                                                    } catch (error) {
                                                        return 'Invalid URL';
                                                    }
                                                },
                                            },
                                        })}
                                        disabled={disabled}
                                        error={errors.away_external_navigation}
                                    />
                                </div>
                            </>
                        </form>
                        <ErrorMessages
                            messages={requsetErrorMessage(
                                error && error.message ? error.message : error,
                            )}
                        />
                    </div>
                ) : (
                    <div className="Page-Loader">
                        <Loader theme="dark" size="big" />{' '}
                    </div>
                )}
                <div style={{ position: 'absolute', top: `${10}%`, left: '50%' }}>
                    {previewHomeSeason && homeSeasonData && (
                        <NotificationCampaignPreview
                            templateNum={templateNum}
                            data={
                                disabled
                                    ? homeSeasonData
                                    : transformData(removePrefix('home_season_', getValues()))
                            }
                            templateData={
                                homeSeasonData.templates[templateNum] ??
                                transformData(removePrefix('home_season_', getValues())).templates[
                                    templateNum
                                ]
                            }
                            onClose={() => showPreviewHomeSeason(false)}
                        />
                    )}
                </div>
                <div style={{ position: 'absolute', top: `${42}%`, left: '50%' }}>
                    {previewHomeNonSeason && homeNonSeasonData && (
                        <NotificationCampaignPreview
                            templateNum={templateNum}
                            data={
                                disabled
                                    ? homeNonSeasonData
                                    : transformData(removePrefix('home_nonseason_', getValues()))
                            }
                            templateData={
                                homeNonSeasonData.templates[templateNum] ??
                                transformData(removePrefix('home_nonseason_', getValues()))
                                    .templates[templateNum]
                            }
                            onClose={() => showPreviewHomeNonSeason(false)}
                        />
                    )}
                </div>
                <div style={{ position: 'absolute', top: `${75}%`, left: '50%' }}>
                    {previewAway && awayData && (
                        <NotificationCampaignPreview
                            templateNum={templateNum}
                            data={
                                disabled
                                    ? awayData
                                    : transformData(removePrefix('away_', getValues()))
                            }
                            templateData={
                                awayData.templates[templateNum] ??
                                transformData(removePrefix('away_', getValues())).templates[
                                    templateNum
                                ]
                            }
                            onClose={() => showPreviewAway(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Gameday;
