import Paths from '../constants/paths';
import Home from '../pages/Home';
import Admins from '../pages/Admins';
import Fans from '../pages/Fans';
import Newsletter from '../pages/Newsletter';
import Notifications from '../pages/Notifications';
import NotificationsPush from '../pages/NotificationsPush';
import Screens from '../pages/Screens';
import Scanners from '../pages/Scanners';
import TicketingKPI from '../pages/TicketingKPI';
import MarketingKPI from '../pages/MarketingKPI';
import Passes from '../pages/Passes';
import Accesses from '../pages/Accesses';
import Seats from '../pages/Seats';
import Teams from '../pages/Teams';
import SeatMaps from '../pages/SeatMaps';
import ShopItems from '../pages/ShopItems';
import Seasons from '../pages/Seasons';
import Sectors from '../pages/Sectors';
import BusinessProfiles from '../pages/BusinessProfiles';
import BusinessPartners from '../pages/BusinessPartners';
import BusinessEvents from '../pages/BusinessEvents';
import Matches from '../pages/Matches';
import SeatGroups from '../pages/SeatGroups';
import InstantRewards from '../pages/InstantRewards';
import Vouchers from '../pages/Vouchers';
import VouchersGastro from '../pages/Vouchers';
import MatchStatistics from '../pages/MatchStatistics';
import Analytics from '../pages/Analytics';
import FanGames from '../pages/FanGames/index';
import FanGameRewards from '../components/FanGameRewards';
import FanGameRanking from '../components/FanGameRanking';
import FanGameWinners from '../components/FanGameWinners';
import Contacts from '../pages/Contacts';
import LotteryGames from '../pages/LotteryGames';
import Reward from '../pages/Reward';
import stores from '../stores';
import Feeds from '../pages/Feeds';
import PassesFriendship from '../pages/PassesFriendship';
import MatchOverview from '../pages/MatchOverview';
import MobileVersion from '../pages/MobileVersion';
import Rewards from '../pages/Rewards';
import News from '../pages/News';

//female
import TeamsFemale from '../pages/TeamsFemale';
import MatchesFemale from '../pages/MatchesFemale';

export const AuthorizedRoutesConfig = [
    {
        path: Paths.HOME,
        isExact: true,
        component: Home,
        props: { matchesStore: stores.matches, calendarStore: stores.calendar },
    },
    {
        path: Paths.ADMINS,
        isExact: false,
        component: Admins,
        props: { store: stores.admins },
    },
    {
        path: Paths.FANS,
        isExact: false,
        component: Fans,
        props: { store: stores.fans },
    },
    {
        path: Paths.NOTIFICATIONS,
        isExact: false,
        component: Notifications,
        props: { store: stores.notifications },
    },
    {
        path: Paths.PUSH_NOTIFICATIONS,
        isExact: false,
        component: NotificationsPush,
        props: { store: stores.notifications },
    },
    {
        path: Paths.MARKETING_KPI,
        isExact: false,
        component: MarketingKPI,
        props: null,
    },
    {
        path: Paths.NEWSLETTER,
        isExact: true,
        component: Newsletter,
        props: null,
    },
    {
        path: Paths.NEWS,
        isExact: false,
        component: News,
        props: { store: stores.news },
    },
    {
        path: Paths.TEAMS,
        isExact: false,
        component: Teams,
        props: { store: stores.teams },
    },
    {
        path: Paths.TEAMS_FEMALE,
        isExact: false,
        component: TeamsFemale,
        props: { store: stores.teams },
    },
    {
        path: Paths.SHOP,
        isExact: false,
        component: ShopItems,
        props: { store: stores.shopItems },
    },
    {
        path: Paths.REWARDS,
        isExact: false,
        component: Rewards,
        props: { store: stores.rewards },
    },
    {
        path: Paths.SEASONS,
        isExact: false,
        component: Seasons,
        props: { store: stores.seasons },
    },
    {
        path: Paths.MATCHES,
        isExact: false,
        component: Matches,
        props: { store: stores.matches },
    },
    {
        path: Paths.MATCHES_FEMALE,
        isExact: false,
        component: MatchesFemale,
        props: { store: stores.matches },
    },
    {
        path: Paths.SCREENS,
        isExact: false,
        component: Screens,
        props: { store: stores.screens },
    },
    {
        path: Paths.SECTORS,
        isExact: false,
        component: Sectors,
        props: { store: stores.sectors },
    },
    {
        path: Paths.SEAT_GROUPS,
        isExact: false,
        component: SeatGroups,
        props: { store: stores.seatGroups },
    },
    {
        path: Paths.SEATS,
        isExact: false,
        component: Seats,
        props: { store: stores.seats },
    },
    {
        path: Paths.FEEDS,
        isExact: Paths.false,
        component: Feeds,
        props: { store: stores.feeds },
    },
    {
        path: Paths.SEAT_MAPS,
        isExact: false,
        component: SeatMaps,
        props: { store: stores.seatMaps },
    },
    {
        path: Paths.SCANNERS,
        isExact: false,
        component: Scanners,
        props: { store: stores.scanners },
    },
    {
        path: Paths.TICKETING_KPI,
        isExact: false,
        component: TicketingKPI,
        props: null,
    },
    {
        path: Paths.PASSES,
        isExact: false,
        component: Passes,
        props: { store: stores.passes },
    },
    {
        path: Paths.PASSES_F,
        isExact: false,
        component: PassesFriendship,
        props: { store: stores.passesFriendship },
    },
    {
        path: Paths.MATCH_OVERVIEW,
        isExact: false,
        component: MatchOverview,
        props: { overviewStore: stores.matchesOverview, freundeStore: stores.passesFriendship },
    },

    {
        path: Paths.E_TICKETS,
        isExact: false,
        component: Accesses,
        props: { store: stores.accesses },
    },
    {
        path: Paths.BUSINESS_PROFILES,
        isExact: false,
        component: BusinessProfiles,
        props: { store: stores.businessProfiles },
    },
    {
        path: Paths.BUSINESS_PARTNERS,
        isExact: false,
        component: BusinessPartners,
        props: { store: stores.businessPartners },
    },
    {
        path: Paths.BUSINESS_EVENTS,
        isExact: false,
        component: BusinessEvents,
        props: { store: stores.businessEvents },
    },
    {
        path: Paths.INSTANT_REWARDS,
        isExact: false,
        component: InstantRewards,
        props: { store: stores.instantRewards },
    },

    {
        path: Paths.VOUCHERS,
        isExact: false,
        component: Vouchers,
        props: { store: stores.vouchers },
    },
    {
        path: Paths.VOUCHERS_GASTRO,
        isExact: false,
        component: VouchersGastro,
        props: { store: stores.vouchers },
    },
    {
        path: Paths.MATCH_STATISTICS,
        isExact: false,
        component: MatchStatistics,
        props: { store: stores.matchStatistics },
    },
    { path: Paths.ANALYTICS, isExact: false, component: Analytics },
    {
        path: Paths.FAN_GAMES,
        isExact: false,
        component: FanGames,
        props: { store: stores.fanGames },
    },
    {
        path: Paths.FAN_GAME_NEW_REWARD,
        isExact: false,
        component: Reward,
        props: { store: stores.fanGames },
    },
    {
        path: Paths.FAN_GAME_REWARD,
        isExact: false,
        component: Reward,
        props: { store: stores.fanGames },
    },
    {
        path: Paths.FAN_GAME_END_GAME_REWARDS,
        isExact: false,
        component: FanGameRewards,
        props: { store: stores.fanGames },
    },
    {
        path: Paths.FAN_GAME_END_GAME_RESULT_WINNERS,
        isExact: false,
        component: FanGameWinners,
        props: {},
    },
    {
        path: Paths.FAN_GAME_END_GAME_RANKING,
        isExact: false,
        component: FanGameRanking,
        props: {},
    },
    {
        path: Paths.CONTACTS,
        isExact: false,
        component: Contacts,
        props: { store: stores.contacts },
    },
    {
        path: Paths.LOTTERY_GAMES,
        isExact: false,
        component: LotteryGames,
        props: { store: stores.lotteryGames },
    },
    {
        path: Paths.MOBILE_APP,
        isExact: true,
        component: MobileVersion,
        props: null,
    },
];
