const femaleLeagues = [
    {
        key: 'pfwl',
        name: 'PFWL',
        id: 'PF_WL',
    },
    {
        key: 'swhl_b',
        name: 'SWHL B',
        id: 'SWHL',
    },
    {
        key: 'swhl_c',
        name: 'SWHL C',
        id: 'SWHLC',
    },
    {
        key: 'swhl_d',
        name: 'SWHL D',
        id: 'SWHLD',
    },
    // {
    //     key: 'andere',
    //     name: 'Andere Teams',
    //     id: 0,
    // },
];
export default femaleLeagues;
