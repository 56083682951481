import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import ErrorMessages from '../../components/ErrorMessages.js';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import InputFile from '../../components/InputFile';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import { useForm } from 'react-hook-form';
import { FaPaperPlane } from 'react-icons/fa';
import API from '../../api/users';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import uniqueById from '../../helpers/uniqueById';
import { observer } from 'mobx-react-lite';

import notificationRecepients from '../../constants/notificationRecipients.js';

const NewNotification = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [recipients, setRecipients] = useState([]); // right select where we manually select users
    const [recipientType, setRecipientType] = useState(null); // left dropdown for selecting a group
    const [resetImageUrl, setResetImageUrl] = useState(null);
    const modalRef = useRef(null);
    const recipientsRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [allUsersModal, setAllUsersModal] = useState(false, modalRef);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);

    const checkboxRef = useRef(null);
    const [showExternalLink, setShowExternalLink] = useState(false);
    const screens = [
        { value: 'home', label: 'Home' },
        { value: 'balance_history', label: 'Balance History' },
        { value: 'games', label: 'Games' },
    ];

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        control,
    } = useForm({
        defaultValues: { text: '', image: '', title: '' },
    });

    const onSearchChangeHandler = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    const bottomReached = (e) => {
        !isLoading && setIsLoading(true);
    };

    const validateAndShowModal = () => {
        if (recipientType === null && recipients.length === 0) {
            // if no group or no user is selected throw error
            setError(translations.no_recipients_message);
            return;
        }

        handleSubmit(submitHandler)();
    };

    const submitHandler = (formValues) => {
        setLoading(true);
        setError(null);

        const { internal_navigation, external_navigation, ...rest } = formValues;
        const notificationData = {
            ...rest,
            data: {
                ...(showExternalLink ? { external_navigation } : { internal_navigation }),
            },
            ...(recipientType ? { to_group: recipientType.value } : { recipients }),
            type: 'admin_push',
        };
        store
            .sendNotification(notificationData, false)
            .then(() => {
                setLoading(false);
                reset({ text: '', image: '', title: '' });
                setUsers([]);
                setRecipients([]);
                recipientsRef.current.state.value = [];
                setModal(true);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
        history.goBack();
    };

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        setPage(1);
    }, [searchQuery]);

    useEffect(() => {
        if (resetImageUrl) {
            reset({ ...watch(), ...resetImageUrl });
        }
    }, [resetImageUrl, reset, watch]);

    useEffect(() => {
        if (isLoading) {
            setPage((page) => page + 1);
        }
        setIsLoading(false);
    }, [isLoading]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await API.getUsers({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: page,
                    },
                });
                if (page === 1) {
                    setUsers(uniqueById(res.data.items));
                } else {
                    setUsers((users) => {
                        return uniqueById([...users, ...res.data.items]);
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };
        searchQuery && getUsers();
    }, [page, searchQuery]);

    useEffect(() => {
        if (recipientType !== null) {
            setRecipients([]);
            recipientsRef.current.state.value = [];
        }
    }, [recipientType, reset]);


    return (
        <div className="New-Notification">
            <div className="New-Notification-Header">
                <Breadcrumb
                    name={`${translations.notification} ${translations.create}`}
                    links={[{ path: Paths.NOTIFICATIONS, name: translations.notifications }]}
                />
            </div>
            <div className="New-Notification-Content">
                <div className="recipients">
                    <span className="sendTo">{translations.send_to}: </span>
                    <div style={{ display: 'block', width: '320px' }}>
                        <Select
                            name="to_group"
                            value={recipientType}
                            isClearable={true}
                            placeholder="Select Type"
                            onChangeCallback={(e) => setRecipientType(e)}
                            options={notificationRecepients.map((recipient) => ({
                                value: recipient.value,
                                label: recipient.label,
                            }))}
                        />
                    </div>

                    <Select
                        ref={recipientsRef}
                        name="recipients"
                        options={users.map((user) => ({
                            value: `${user.first_name} ${user.last_name} [${user.email}]`,
                            label: `${user.first_name} ${user.last_name} [${user.email}]`,
                            id: user.id,
                        }))}
                        disabled={recipientType !== null}
                        multiple={true}
                        onChangeCallback={(e) => setRecipients(e.map((u) => u.id))}
                        onInputChange={onSearchChangeHandler}
                        hasError={true}
                        placeholder={translations.type_to_search_users_notifications}
                        noOptionsMsg={translations.no_users_found}
                        isLoading={isLoading}
                        bottomReached={bottomReached}
                        onBlur={() => setPage(1)}
                    ></Select>
                </div>

                <form onSubmit={handleSubmit(validateAndShowModal)}>
                    <Input
                        label={translations.title}
                        name="title"
                        {...register('title', {
                            required: true,
                        })}
                        error={errors.title}
                    />
                    <Textarea
                        label={translations.text}
                        name="text"
                        {...register('text', {
                            required: true,
                        })}
                        error={errors.text}
                    />

                    <InputFile
                        label={translations.image}
                        name="image"
                        {...register('image', {
                            required: false,
                        })}
                        error={errors.image}
                        pathToCloudFolder="uploads/notifications"
                        resetValue={(val) => setResetImageUrl({ image: val })}
                    />
                    <div style={{ width: '125px' }}>
                        <Input
                            label={translations.credits}
                            name="credits"
                            placeholder="0"
                            type="number"
                            {...register('credits', {
                                required: false,
                                valueAsNumber: true,
                            })}
                            error={errors.credits}
                        />
                    </div>
                    <div
                        style={{
                            width: '80%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '50px',
                        }}
                    >
                        <Checkbox
                            name="external_link"
                            text="External Link"
                            defaultValue="false"
                            ref={checkboxRef}
                            outerStyle={{ alignSelf: 'center' }}
                            onChange={() => setShowExternalLink((prev) => !prev)}
                        />

                        {showExternalLink ? (
                            <Input
                                label={translations.external_link}
                                name="external_navigation"
                                placeholder="Type here..."
                                type="text"
                                {...register('external_navigation', {
                                    required: false,
                                    validate: {
                                        checkUrl: (value) => {
                                            if (!value) {
                                                return true;
                                            }
                                            try {
                                                new URL(value);
                                                return true;
                                            } catch (error) {
                                                return 'Invalid URL';
                                            }
                                        },
                                    },
                                })}
                                style={{ flex: '1' }}
                                error={errors.external_navigation}
                            />
                        ) : (
                            <div style={{ width: '235px' }}>
                                <Select
                                    label="Screen"
                                    name="internal_navigation"
                                    placeholder="Select Screen"
                                    options={screens.map((screen) => ({
                                        value: screen.value,
                                        label: screen.label,
                                    }))}
                                    control={control}
                                    error={errors.internal_navigation}
                                />
                            </div>
                        )}
                    </div>

                    <div className="controls">
                        <Button
                            style={{ width: '120px' }}
                            type="submit"
                            label={translations.send}
                            loading={loading}
                        >
                            <FaPaperPlane style={{ marginRight: '5px' }} />
                        </Button>
                    </div>
                </form>
                <ErrorMessages
                    messages={requsetErrorMessage(error && error.message ? error.message : error)}
                />
            </div>
            {modal && (
                <Modal
                    title={translations.notification}
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>Notification sent!</p>
                    <div className="Modal-Controls">
                        <Button
                            label="OK"
                            onClick={() => setModal(false)}
                            style={{ width: '80px' }}
                        />
                    </div>
                </Modal>
            )}
            {allUsersModal && (
                <Modal
                    title={translations.notification}
                    ref={modalRef}
                    onClose={() => setAllUsersModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {translations.send_to_all_message}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            type="button"
                            label="OK"
                            onClick={() => {
                                handleSubmit(submitHandler)();
                                setAllUsersModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            type="button"
                            label="Cancel"
                            onClick={() => {
                                setAllUsersModal(false);
                            }}
                            style={{ width: '80px', marginLeft: '5px' }}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default observer(NewNotification);
