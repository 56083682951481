import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
//import Select from '../../components/Select';
import TabSwitcher from '../../components/TabSwitcher';

import femaleLeagues from '../../constants/femaleLeagues';
// import { formatLeagueOptions, findDefaultOption } from '../../helpers/femaleLeagueFilter';

const Teams = ({ store }) => {
    const history = useHistory();
    //const [other, setOther] = useState(false);
    const [leagueID, setLeagueID] = useState(null);

    useEffect(() => {
        let params = {
            // other: other,
            league_enum: leagueID ? leagueID : undefined,
        };
        leagueID && store.getTeams(params);
    }, [store, /*other,*/ leagueID]);

    const handleTeam = (id) => {
        store.selectTeam(id);
        history.push(`/teams-female/${id}`);
    };

    const handleFemaleLeagueChange = (e) => {
        let selectedLeague = femaleLeagues.find((league) => league.name === e);
        selectedLeague && setLeagueID(selectedLeague.id);
    };

    // const handleLeagueChange = (e) => {
    //     if (e.value === 'other') {
    //         setOther(true);
    //     } else {
    //         setOther(false);
    //     }
    // };
    return (
        <>
            <TabSwitcher onTabChange={(e) => handleFemaleLeagueChange(e)}>
                <TabSwitcher.Tabs initialTabName={femaleLeagues[0].name}>
                    {femaleLeagues.map((league, index) => {
                        return <TabSwitcher.TabPill key={league.key} tabName={league.name} />;
                    })}
                </TabSwitcher.Tabs>
                {femaleLeagues.map((league) => {
                    // let formatOptions = formatLeagueOptions(league.name);
                    // let defaultOption = findDefaultOption(league.name);

                    return (
                        <TabSwitcher.TabPanel activeWhenTabName={league.name}>
                            {/* <div className="tab-controls">
                                {formatOptions && defaultOption && (
                                    <Select
                                        label="League"
                                        defaultValue={defaultOption}
                                        options={formatOptions}
                                        onChange={(e) => {
                                            handleLeagueChange(e);
                                        }}
                                    />
                                )}
                            </div> */}

                            <Table
                                isLoading={store.isLoading}
                                tableName={translations.teams}
                                columns={[
                                    {
                                        Header: 'ID',
                                        accessor: 'id',
                                        Cell: ({ value, row }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: translations.logo,
                                        accessor: 'logo_url',
                                        Cell: ({ value, row }) => (
                                            <div>
                                                <ItemImage
                                                    imgSrc={value}
                                                    alt="team"
                                                    listView={true}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: translations.title,
                                        accessor: 'title',
                                        Cell: ({ value, row }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: '',
                                        accessor: 'action',
                                        Cell: ({ row }) => (
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        handleTeam(row.cells[0].value);
                                                    }}
                                                    title={translations.show}
                                                >
                                                    <FaSearch />
                                                </Button>
                                            </div>
                                        ),
                                    },
                                ]}
                                data={store.teams.map(({ id, logo_url, title }) => {
                                    return {
                                        id,
                                        logo_url,
                                        title,
                                    };
                                })}
                            />
                        </TabSwitcher.TabPanel>
                    );
                })}
            </TabSwitcher>
        </>
    );
};

export default observer(Teams);
