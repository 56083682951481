import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams, useHistory } from 'react-router-dom';
import getStaffMemberFormInput from '../Teams/getStaffMemberFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import API from '../../api/teams';
import useStaffData from '../../customHooks/useStaffData';

const StaffMember = ({ store }) => {
    const history = useHistory();
    const { team_id, staff_member_id } = useParams();
    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [staffMember, setStaffMember] = useState(null);

    const [staffRoles, staffSection, staffCount] = useStaffData();

    const staffMemberModel = clearObjProps({ ...staffMember }, [
        'id',
        'team_id',
        'deleted_at',
        'created_at',
        'updated_at',
    ]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        let nowDate = new Date(e.date_of_birth);
        let changedDate = new Date(nowDate.setHours(6));

        API.editStaff(staff_member_id, {
            ...e,
            date_of_birth: changedDate,
        })
            .then((e) => {
                setEdit(false);
                setStaffMember(e.data);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        !store.selectedTeam && store.getTeam(team_id);
    }, [store, team_id]);

    useEffect(() => {
        const getStaffMember = async () => {
            try {
                const res = await API.getStaffMember(staff_member_id);
                setStaffMember(res.data);
            } catch (e) {
                console.log(e);
            }
        };
        getStaffMember();
    }, [staff_member_id]);

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.push({ pathname: `/teams-female/${team_id}`, state: { from: 'staff' } });
            }
        };
    }, [history, team_id]);

    return staffMember ? (
        <Item
            links={[
                {
                    path: Paths.TEAMS,
                    name: translations.teams,
                },
                {
                    path: `/teams-female/${team_id}`,
                    state: { from: 'staff' },
                    name: store?.selectedTeam?.title,
                },
            ]}
            name={staffMember?.first_name + ' ' + staffMember?.last_name}
            id={`[ staff_member_ID: ${staff_member_id} ]`}
            itemModel={staffMemberModel}
            edit={edit}
            onSubmit={onSubmitHandler}
            onEdit={() => setEdit(true)}
            onCancel={() => {
                error && setError(null);
                setEdit(false);
            }}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, error, disabled, index, control }) =>
                getStaffMemberFormInput({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setResetProp({ [name]: value }),
                    control,
                    team_acronym: store?.selectedTeam?.totomat_acronym,
                    staffRoles,
                    staffSection,
                    staffCount,
                })
            }
            resetProp={resetProp}
        >
            <ItemImage imgSrc={staffMember?.image_url} isAvatar />
        </Item>
    ) : null;
};

export default observer(StaffMember);
