import React, { useState, useEffect, useMemo, useRef } from 'react';
import ErrorMessages from '../../../components/ErrorMessages.js/index.js';
import Input from '../../../components/Input/index.js';
import Textarea from '../../../components/Textarea/index.js';
import InputFile from '../../../components/InputFile/index.js';
import Checkbox from '../../../components/Checkbox/index.js';
import Select from '../../../components/Select/index.js';
import Button from '../../../components/Button/index.js';
import Loader from '../../../components/Loader/index.js';
import DatePicker from '../../../components/DatePicker';
import ItemImage from '../../../components/ItemImage/index.js';
import NotificationCampaignPreview from './../../../components/NotificationCampaignPreview/NotificationCampaignPreview';

import requsetErrorMessage from '../../../helpers/requsetErrorMessage.js';
import { useForm } from 'react-hook-form';
import translations from '../../../translations/de.js';

import { ISOStoStingTimeOnly } from '../../../helpers/dateToISOStringInObj.js';
import useModal from '../../../customHooks/useModal.js';

import {
    RecipientTypesEnum,
    getRecipientByValue,
} from '../../../constants/notificationRecipients.js';

import { NotificationGroupsEnum } from '../../../constants/notificationGroupsEnum.js';
import { NotificationTypesEnum } from '../../../constants/notificationTypeEnum.js';
import { NotificationCampaignsEnum } from '../../../constants/notificationCampaignsEnum.js';
import { NotificationScreensOptions } from '../../../constants/notificationScreensOptions.js';

import API from '../../../api/notificationsPush.js';

import './FieldsStyle.scss';

const NoShow = ({ isEditable, closeEditable }) => {
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(!isEditable);

    const [threeGamesData, setThreeGamesData] = useState(null);
    const [fiveGamesData, setFiveGamesData] = useState(null);

    const [pageLoader, setPageLoader] = useState(false);
    const [loading, setLoading] = useState(false);

    const [resetImageUrlThreeGames, setResetImageUrlThreeGames] = useState(
        threeGamesData?.templates?.[0]?.image,
    );
    const [resetImageUrlFiveGames, setResetImageUrlFiveGames] = useState(
        fiveGamesData?.templates?.[0]?.image,
    );

    const [showExternalLinkThreeGames, setShowExternalLinkThreeGames] = useState(false);
    const [showExternalLinkTFiveGames, setShowExternalLinkFiveGames] = useState(false);

    const previewThreeGameRef = useRef(null);
    const previewFiveGameRef = useRef(null);

    const [previewThree, showPreviewThree] = useModal(false, previewThreeGameRef, false, false);
    const [previewFive, showPreviewFive] = useModal(false, previewFiveGameRef, false, false);

    const formFields = useMemo(
        () => ({
            three_games_text: threeGamesData?.templates?.[0].text,
            three_games_image: threeGamesData?.templates?.[0].image,
            three_games_title: threeGamesData?.templates?.[0].title,
            three_games_delivery_time: ISOStoStingTimeOnly(threeGamesData?.delivery_time),
            three_games_start_date: threeGamesData?.start_date,
            three_games_paused: threeGamesData?.paused,
            three_games_credits: threeGamesData?.credits,
            three_games_internal_navigation: threeGamesData?.data?.internal_navigation,
            three_games_external_navigation: threeGamesData?.data?.external_navigation,
            //
            five_games_text: fiveGamesData?.templates?.[0]?.text,
            five_games_image: fiveGamesData?.templates?.[0]?.image,
            five_games_title: fiveGamesData?.templates?.[0]?.title,
            five_games_delivery_time: ISOStoStingTimeOnly(fiveGamesData?.delivery_time),
            five_games_start_date: fiveGamesData?.start_date,
            five_games_paused: fiveGamesData?.paused,
            five_games_credits: fiveGamesData?.credits,
            five_games_internal_navigation: fiveGamesData?.data?.internal_navigation,
            five_games_external_navigation: fiveGamesData?.data?.external_navigation,
        }),
        [threeGamesData, fiveGamesData],
    );

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        formState: { errors },
        reset,
        control,
    } = useForm({
        defaultValues: formFields,
    });

    const removePrefix = (prefix, sourceData) => {
        let newData = {};
        Object.keys(sourceData).forEach((key) => {
            if (key.startsWith(prefix)) {
                const newKey = key.replace(prefix, '');
                newData[newKey] = sourceData[key];
            }
        });
        return newData;
    };

    function transformData(data) {
        const templates = [];

        for (let i = 1; i <= 3; i++) {
            templates.push({
                title: data[`title`],
                text: data[`text`],
                image: data[`image`],
            });
        }
        return {
            templates,
            delivery_time: new Date(data?.delivery_time).toISOString().slice(11, 19),
            start_date: data.start_date,
            paused: data.paused,
            campaign_type: NotificationCampaignsEnum.NO_SHOW,
        };
    }

    const handleClose = () => {
        reset(formFields);
        setResetImageUrlFiveGames(null);
        setResetImageUrlThreeGames(null);
        closeEditable();
    };

    const submitHandler = (data) => {
        setLoading(true);
        setError(null);
        let threeGamesFormData = {
            ...removePrefix('three_games_', data),
            group: NotificationGroupsEnum.NOT_ATTENDED_LAST_3_GAMES,
            notification_type: NotificationTypesEnum.CAMPAIGN_NO_SHOW,
            campaign_type: NotificationCampaignsEnum.NO_SHOW,
        };

        let fiveGamesFormData = {
            ...removePrefix('five_games_', data),
            group: NotificationGroupsEnum.NOT_ATTENDED_LAST_5_GAMES,
            notification_type: NotificationTypesEnum.CAMPAIGN_NO_SHOW,
            campaign_type: NotificationCampaignsEnum.NO_SHOW,
        };
        /// data property formatName
        const {
            internal_navigation: threeInternalNav,
            start_date: threeStartDate,
            delivery_time: threeDeliveryTime,
            external_navigation: threeExternalNav,
            title: titleThreeGames,
            text: textThreeGames,
            image: imageThreeGames,
            ...threeRest
        } = threeGamesFormData;

        const notifDataThreeGames = {
            ...threeRest,
            start_date: threeStartDate ? new Date(threeStartDate).toISOString() : threeStartDate,
            delivery_time: threeDeliveryTime
                ? new Date(threeDeliveryTime).toISOString()
                : threeDeliveryTime,
            data: {
                ...(showExternalLinkThreeGames
                    ? { external_navigation: threeExternalNav }
                    : { internal_navigation: threeInternalNav }),
            },
            templates: [
                {
                    title: titleThreeGames,
                    text: textThreeGames,
                    image: imageThreeGames,
                },
            ],
        };

        const {
            internal_navigation: fiveInternalNav,
            start_date: fiveStartDate,
            delivery_time: fiveDeliveryTime,
            external_navigation: fiveExternalNav,
            title: titleFiveGames,
            text: textFiveGames,
            image: imageFiveGames,
            ...fiveRest
        } = fiveGamesFormData;

        const notifDataFiveGames = {
            ...fiveRest,
            start_date: fiveStartDate ? new Date(fiveStartDate).toISOString() : fiveStartDate,
            delivery_time: fiveDeliveryTime
                ? new Date(fiveDeliveryTime).toISOString()
                : fiveDeliveryTime,
            data: {
                ...(showExternalLinkTFiveGames
                    ? { external_navigation: fiveExternalNav }
                    : { internal_navigation: fiveInternalNav }),
            },
            templates: [
                {
                    title: titleFiveGames,
                    text: textFiveGames,
                    image: imageFiveGames,
                },
            ],
        };
        const reqBody = { items: [{ ...notifDataThreeGames }, { ...notifDataFiveGames }] };
        try {
            API.editNotification(reqBody);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
            closeEditable();
        }
    };

    useEffect(() => {
        const getCampaign = async () => {
            try {
                setPageLoader(true);
                const gamesData = await API.getCampaign({
                    params: {
                        campaign_type: NotificationCampaignsEnum.NO_SHOW,
                    },
                });
                if (
                    gamesData.data.items[0].group ===
                    NotificationGroupsEnum.NOT_ATTENDED_LAST_3_GAMES
                ) {
                    setThreeGamesData(gamesData.data.items[0]);
                }
                if (
                    gamesData.data.items[1].group ===
                    NotificationGroupsEnum.NOT_ATTENDED_LAST_5_GAMES
                ) {
                    setFiveGamesData(gamesData.data.items[1]);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setPageLoader(false);
            }
        };
        getCampaign();
    }, []);

    useEffect(() => {
        setDisabled(!isEditable);
    }, [isEditable]);

    useEffect(() => {
        if (threeGamesData !== null && fiveGamesData !== null) {
            reset(formFields);
        }
    }, [threeGamesData, fiveGamesData, formFields, reset]);

    useEffect(() => {
        if (resetImageUrlThreeGames) {
            reset({ ...watch(), ...resetImageUrlThreeGames });
        }
    }, [resetImageUrlThreeGames, reset, watch]);

    useEffect(() => {
        if (resetImageUrlFiveGames) {
            reset({ ...watch(), ...resetImageUrlFiveGames });
        }
    }, [resetImageUrlFiveGames, reset, watch]);

    return (
        <div className="New-Notification">
            {!disabled && (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: '10px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button label={translations.cancel} type="button" onClick={handleClose} />
                    <Button
                        loading={loading}
                        label={translations.save}
                        type="submit"
                        onClick={handleSubmit(submitHandler)}
                    />
                </div>
            )}
            {/* FIRST FORM */}
            {!pageLoader ? (
                <div>
                    <div className="preview-wrapper">
                        <div className="sendTo">
                            <span>
                                {translations.send_to}:{` `}
                                {
                                    getRecipientByValue(
                                        RecipientTypesEnum.NOT_ATTENDED_LAST_3_GAMES,
                                    )?.label
                                }
                            </span>
                        </div>
                        <div>
                            <Button
                                type="button"
                                theme="gray"
                                label={translations.preview}
                                loading={loading}
                                onClick={() => showPreviewThree(true)}
                            />
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(submitHandler)}>
                        <>
                            <Input
                                label={translations.title}
                                placeholder={translations.title}
                                disabled={disabled}
                                name="three_games_title"
                                {...register('three_games_title', {
                                    required: true,
                                })}
                                error={errors.three_games_title}
                            />
                            <Textarea
                                label={translations.text}
                                disabled={disabled}
                                placeholder={translations.text}
                                name="three_games_text"
                                {...register('three_games_text', {
                                    required: true,
                                })}
                                error={errors.three_games_text}
                            />
                            <InputFile
                                label={translations.image}
                                name="three_games_image"
                                {...register('three_games_image', {
                                    required: false,
                                })}
                                error={errors.three_games_image}
                                disabled={disabled}
                                pathToCloudFolder="uploads/notifications"
                                resetValue={(val) =>
                                    setResetImageUrlThreeGames({ three_games_image: val })
                                }
                            />
                            <ItemImage
                                imgSrc={
                                    resetImageUrlThreeGames?.three_games_image ??
                                    threeGamesData?.templates?.[0]?.image
                                }
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '30px',
                                    alignItems: 'center',
                                    width: '820px',
                                }}
                            >
                                <div className="timepicker">
                                    <DatePicker
                                        label={translations.delivery_time}
                                        name="three_games_delivery_time"
                                        timeOnly={true}
                                        selectText="Pick Time"
                                        control={control}
                                        disabled={disabled}
                                        error={errors.three_games_delivery_time}
                                    />
                                </div>
                                <div className="datepicker">
                                    <DatePicker
                                        label={translations.starting_date}
                                        name="three_games_start_date"
                                        defaultValue={new Date()}
                                        withTime={true}
                                        control={control}
                                        selectText="Select a date..."
                                        disabled={disabled}
                                        error={errors.three_games_start_date}
                                    />
                                </div>

                                <div>
                                    <Checkbox
                                        text={translations.paused}
                                        name={'three_games_paused'}
                                        {...register('three_games_paused', { required: false })}
                                        disabled={disabled}
                                        error={errors.three_games_paused}
                                    />
                                </div>
                            </div>
                            <div className="externalLinkWrapper">
                                <div className="checkbox">
                                    <Checkbox
                                        name="three_games_external_link"
                                        text="External Link"
                                        defaultValue="false"
                                        disabled={disabled}
                                        outerStyle={{ alignSelf: 'center' }}
                                        onChange={() =>
                                            setShowExternalLinkThreeGames((prev) => !prev)
                                        }
                                    />
                                </div>

                                {showExternalLinkThreeGames ? (
                                    <Input
                                        label={translations.external_link}
                                        name="three_games_external_navigation"
                                        placeholder="Type here..."
                                        type="text"
                                        {...register('three_games_external_navigation', {
                                            required: false,
                                            validate: {
                                                checkUrl: (value) => {
                                                    if (!value) {
                                                        return true;
                                                    }
                                                    try {
                                                        new URL(value);
                                                        return true;
                                                    } catch (error) {
                                                        return 'Invalid URL';
                                                    }
                                                },
                                            },
                                        })}
                                        disabled={disabled}
                                        style={{ flex: '1' }}
                                        error={errors.three_games_external_link}
                                    />
                                ) : (
                                    <div className="screen-picker">
                                        <Select
                                            label="Screen"
                                            name="three_games_internal_navigation"
                                            placeholder="Select Screen"
                                            control={control}
                                            options={NotificationScreensOptions.map((screen) => ({
                                                value: screen.value,
                                                label: screen.label,
                                            }))}
                                            disabled={disabled}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                        <div
                            style={{
                                width: '100%',
                                borderTop: `1px solid ${disabled ? 'gray' : 'black'}`,
                                height: '1px',
                                marginBottom: '15px',
                            }}
                        />
                    </form>
                    <form>
                        {/* SECOND FORM */}
                        <>
                            <div>
                                <div className="preview-wrapper">
                                    <div>
                                        <div className="sendTo">
                                            <span>
                                                {translations.send_to}:{` `}
                                                {
                                                    getRecipientByValue(
                                                        RecipientTypesEnum.NOT_ATTENDED_LAST_5_GAMES,
                                                    )?.label
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            type="button"
                                            theme="gray"
                                            label={translations.preview}
                                            loading={loading}
                                            onClick={() => showPreviewFive(true)}
                                        />
                                    </div>
                                </div>

                                <>
                                    <Input
                                        label={translations.title}
                                        placeholder={translations.title}
                                        disabled={disabled}
                                        name="five_games_title"
                                        {...register('five_games_title', {
                                            required: true,
                                        })}
                                        error={errors.five_games_title}
                                    />
                                    <Textarea
                                        label={translations.text}
                                        disabled={disabled}
                                        placeholder={translations.text}
                                        name="five_games_text"
                                        {...register('five_games_text', {
                                            required: true,
                                        })}
                                        error={errors.five_games_text}
                                    />
                                    <InputFile
                                        label={translations.image}
                                        name="five_games_image"
                                        {...register('five_games_image', {
                                            required: false,
                                        })}
                                        error={errors.five_games_image}
                                        disabled={disabled}
                                        pathToCloudFolder="uploads/notifications"
                                        resetValue={(val) =>
                                            setResetImageUrlFiveGames({ five_games_image: val })
                                        }
                                    />
                                    <ItemImage
                                        imgSrc={
                                            resetImageUrlFiveGames?.five_games_image ??
                                            fiveGamesData?.templates?.[0]?.image
                                        }
                                    />

                                    <div className="datepickerWrapper">
                                        <div className="timepicker">
                                            <DatePicker
                                                timeOnly={true}
                                                label={translations.delivery_time}
                                                name="five_games_delivery_time"
                                                selectText="Pick Time"
                                                control={control}
                                                disabled={disabled}
                                                error={errors.five_games_delivery_time}
                                            />
                                        </div>
                                        <div className="datepicker">
                                            <DatePicker
                                                label={translations.starting_date}
                                                name="five_games_start_date"
                                                control={control}
                                                selectText="Select a date..."
                                                disabled={disabled}
                                                error={errors.five_games_start_date}
                                            />
                                        </div>

                                        <div>
                                            <Checkbox
                                                text={translations.paused}
                                                name={'paused'}
                                                {...register('five_games_paused', {
                                                    required: false,
                                                })}
                                                disabled={disabled}
                                                error={errors.five_games_paused}
                                            />
                                        </div>
                                    </div>
                                    <div className="externalLinkWrapper">
                                        <div className="checkbox">
                                            <Checkbox
                                                name="external_link"
                                                text="External Link"
                                                defaultValue="false"
                                                disabled={disabled}
                                                outerStyle={{ alignSelf: 'center' }}
                                                onChange={() =>
                                                    setShowExternalLinkFiveGames((prev) => !prev)
                                                }
                                            />
                                        </div>
                                        {showExternalLinkTFiveGames ? (
                                            <Input
                                                label={translations.external_link}
                                                name="five_games_external_link"
                                                placeholder="Type here..."
                                                type="text"
                                                {...register('five_games_external_link', {
                                                    required: false,
                                                    validate: {
                                                        checkUrl: (value) => {
                                                            if (!value) {
                                                                return true;
                                                            }
                                                            try {
                                                                new URL(value);
                                                                return true;
                                                            } catch (error) {
                                                                return 'Invalid URL';
                                                            }
                                                        },
                                                    },
                                                })}
                                                disabled={disabled}
                                                style={{ flex: '1' }}
                                                error={errors.five_games_external_link}
                                            />
                                        ) : (
                                            <div className="screen-picker">
                                                <Select
                                                    label="Screen"
                                                    name="five_games_internal_navigation"
                                                    placeholder="Select Screen"
                                                    control={control}
                                                    options={NotificationScreensOptions.map(
                                                        (screen) => ({
                                                            value: screen.value,
                                                            label: screen.label,
                                                        }),
                                                    )}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </>
                            </div>
                        </>
                    </form>
                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                </div>
            ) : (
                <div className="Page-Loader">
                    <Loader theme="dark" size="big" />{' '}
                </div>
            )}
            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                {previewThree && threeGamesData && (
                    <NotificationCampaignPreview
                        data={
                            disabled
                                ? threeGamesData
                                : transformData(removePrefix('three_games_', getValues()))
                        }
                        templateData={threeGamesData?.templates[0]}
                        onClose={() => showPreviewThree(false)}
                    />
                )}
            </div>
            <div style={{ position: 'absolute', top: '75%', left: '50%' }}>
                {previewFive && fiveGamesData && (
                    <NotificationCampaignPreview
                        data={
                            disabled
                                ? fiveGamesData
                                : transformData(removePrefix('five_games_', getValues()))
                        }
                        templateData={fiveGamesData?.templates[0]}
                        onClose={() => showPreviewFive(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default NoShow;
