const dateToISOStringInObj = (obj) => {
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        if (value instanceof Date) {
            const ISOString = value.toISOString();
            obj[key] = ISOString;
        }
    });
    return obj;
};

export const ISOStoStingTimeOnly = (val) => {
    if (val) {
        return new Date(`1970-01-01T${val}Z`);
    } else return null;
};

export default dateToISOStringInObj;
