export const RecipientTypesEnum = {
    WITH_BIRTHDAY: 'WITH_BIRTHDAY',
    ALL_USERS: 'ALL',
    NOT_ATTENDED_LAST_3_GAMES: 'NOT_ATTENDED_LAST_3_GAMES',
    NOT_ATTENDED_LAST_5_GAMES: 'NOT_ATTENDED_LAST_5_GAMES',
    SEASON_TICKET_HOLDERS: 'SEASON_TICKET_HOLDERS',
    NON_SEASON_TICKET_HOLDERS: 'NON_SEASON_TICKET_HOLDERS',
};

const notificationRecepients = [
    { label: 'All users with birthdays', value: RecipientTypesEnum.WITH_BIRTHDAY },
    { label: 'All users', value: RecipientTypesEnum.ALL_USERS },
    { label: '3 Games no show', value: RecipientTypesEnum.NOT_ATTENDED_LAST_3_GAMES },
    { label: '5 Games no show', value: RecipientTypesEnum.NOT_ATTENDED_LAST_5_GAMES },
    { label: 'Season ticket holders', value: RecipientTypesEnum.SEASON_TICKET_HOLDERS },
    { label: 'Non season ticket holders', value: RecipientTypesEnum.NON_SEASON_TICKET_HOLDERS },
];

export const getRecipientByValue = (value) =>
    notificationRecepients.find((item) => item.value === value);

export default notificationRecepients;
