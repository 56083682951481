import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { useHistory, useParams } from 'react-router-dom';
import getStaffMemberFormInput from '../Teams/getStaffMemberFormInput';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import API from '../../api/teams';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearEmptyFields from '../../helpers/clearEmptyFields';
import useStaffData from '../../customHooks/useStaffData';

const NewStaffMember = ({ store }) => {
    const history = useHistory();
    const { team_id } = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [staffRoles, staffSection, staffCount] = useStaffData();

    useEffect(() => {
        !store.selectedTeam && store.getTeam(team_id);
    }, [store, team_id]);

    return store.selectedTeam ? (
        <Item
            links={[
                { path: Paths.TEAMS, name: translations.teams },
                { path: `/teams-female/${store.selectedTeam.id}`, name: store.selectedTeam.title },
            ]}
            name={`${translations.staff} ${translations.create}`}
            itemModel={{
                first_name: '',
                last_name: '',
                image_url: '',
                date_of_birth: new Date(new Date().setHours(6, 0, 0, 0)),
                role: '',
                section: '',
                elite_sport_link: '',
                sort_num: '',
            }}
            edit={true}
            error={error}
            loading={loading}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                API.createStaff(clearEmptyFields({ ...event, team_id: parseInt(team_id) }))
                    .then(() => {
                        setLoading(false);
                        history.push({
                            pathname: `/teams-female/${team_id}`,
                            state: { from: 'staff' },
                        });
                    })
                    .catch((err) => {
                        setError(requsetErrorMessage(err && err.message));
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            determineInputType={({ value, name, register, error, disabled, index, control }) =>
                getStaffMemberFormInput({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setAvatar({ [name]: value }),
                    control,
                    team_acronym: store.selectedTeam.totomat_acronym,
                    staffRoles,
                    staffSection,
                    staffCount,
                })
            }
            resetProp={avatar}
        ></Item>
    ) : null;
};

export default NewStaffMember;
