import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import './Match.scss';
import Button from '../../components/Button';
import getMatchFormInput from './getMatchFormInput';
import { useHistory, useParams } from 'react-router-dom';
import formatName from './../../helpers/formatName';
import clearObjProps from '../../helpers/clearObjProps';
import { FaPlus } from 'react-icons/fa';
import translations from '../../translations/de';

const Match = ({ store }) => {
    const history = useHistory();
    const { match_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [gamesNum, setGamesNum] = useState(undefined);
    const [error, setError] = useState(null);
    const ownerID = store.teams.length && store.teams.find((t) => t.is_owner).id;
    const [homeTeamOptions, setHomeTeamOptions] = useState(store.teams);
    const [awayTeamOptions, setAwayTeamOptions] = useState(store.teams);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.getMatch(match_id).then(() => {
            store.selectedMatch && setGamesNum(store.selectedMatch.fan_games.length);
        });
    }, [store, match_id]);

    const matchModel = clearObjProps({ ...store.selectedMatch }, [
        'id',
        'home_team',
        'away_team',
        'fan_games',
        'season_id',
    ]);
    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        /// before editing check if values are correct
        if (
            event.score_home_team ===
            event.one_third_home_score + event.two_thirds_home_score + event.three_thirds_home_score
        ) {
            if (
                event.score_away_team ===
                event.one_third_away_score +
                    event.two_thirds_away_score +
                    event.three_thirds_away_score
            ) {
                store
                    .editMatch({
                        ...event,
                    })
                    .then(() => {
                        setEdit(false);
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            } else {
                setError(translations.home_score_error);
                setLoading(false);
            }
        } else {
            setError(translations.guest_score_error);
            setLoading(false);
        }
    };

    const createFanGames = () => {
        store
            .createMatchFanGames()
            .then(() => {
                //no response, forcing reload to get new data
                window.location.reload();
            })
            .catch(setError);
    };

    const onTeamChangeHandler = (field, teamID) => {
        if (field === 'home_team_id') {
            if (!teamID) {
                setHomeTeamOptions(store.teams);
                return;
            }
            if (teamID === ownerID) {
                setAwayTeamOptions(store.teams.filter((t) => t.id !== teamID));
            } else {
                setAwayTeamOptions([store.teams.find((t) => t.id === ownerID)]);
            }
        } else if (field === 'away_team_id') {
            if (!teamID) {
                setAwayTeamOptions(store.teams);
                return;
            }
            if (teamID === ownerID) {
                setHomeTeamOptions(store.teams.filter((t) => t.id !== teamID));
            } else {
                setHomeTeamOptions([store.teams.find((t) => t.id === ownerID)]);
            }
        }
    };
    return store.selectedMatch ? (
        <>
            <Item
                links={[{ path: '/matches', name: translations.matches }]}
                name={
                    store.selectedMatch.home_team.title +
                    ' - ' +
                    store.selectedMatch.away_team.title
                }
                id={`[ match_ID: ${store.selectedMatch.id} ]`}
                itemModel={{ ...matchModel }}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                insertDummyFieldAt={7}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getMatchFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        teams:
                            name === 'home_team_id'
                                ? homeTeamOptions
                                : name === 'away_team_id'
                                ? awayTeamOptions
                                : null,
                        onTeamChange: onTeamChangeHandler,
                    })
                }
            >
                {' '}
                <div className="Item-Content-Image">
                    <img src={store.selectedMatch.home_team.logo_url} alt="team one" />
                    VS
                    <img src={store.selectedMatch.away_team.logo_url} alt="team two" />
                </div>
            </Item>
            <div style={{ marginLeft: '2.5%' }}>
                {gamesNum !== undefined && gamesNum < 3 ? (
                    <Button
                        label="Fan Games"
                        // onClick={() => {
                        //     history.push(`/matches/${store.selectedMatch.id}/new_fan_game`);
                        // }}
                        onClick={createFanGames}
                    >
                        <FaPlus style={{ marginRight: '5px' }} />
                    </Button>
                ) : null}
            </div>

            {store.selectedMatch.fan_games.length !== 0 && (
                <div className="Fan-Games">
                    <p className="Fan-Games-Header">Fan Games</p>
                    {store.selectedMatch.fan_games.map((game) => (
                        <div
                            className="game"
                            key={game.id}
                            onClick={() => {
                                store.selectGame(game.id);
                                history.push(
                                    `/matches/${store.selectedMatch.id}/fan-game/${game.id}`,
                                );
                            }}
                        >
                            <span>{formatName(game.fan_game_type)}</span>
                        </div>
                    ))}
                </div>
            )}
        </>
    ) : null;
};

export default observer(Match);
