import React, { useEffect } from 'react';
import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import Matches from './Matches';
import Match from '../Matches/Match';
import NewMatch from './/NewMatch';
import NewFanGame from '../Matches/NewFanGame';
import FanGame from '../Matches/FanGame';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const MatchesTab = ({ store }) => {
    useEffect(() => {
        store.getTeams();
    }, [store]);

    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.MATCHES_FEMALE}>
                <Matches store={store} />
            </Route>
            <Route exact path={Paths.MATCH_FEMALE}>
                <Match store={store} />
            </Route>
            <Route exact path={Paths.NEW_MATCH_FEMALE}>
                <NewMatch store={store} />
            </Route>
            <Route exact path={Paths.MATCH_NEW_FAN_GAME}>
                <NewFanGame store={store} />
            </Route>
            <Route exact path={Paths.MATCH_FAN_GAME}>
                <FanGame store={store} />
            </Route>
        </Page>
    );
};

export default observer(MatchesTab);
