import api from './apiLibrary';

const API = {
    createNotificationCampaign(params) {
        return api.post('/api/admin/notification-campaign', params);
    },
    getCampaigns(params) {
        return api.get('/api/admin/notification-campaign', params);
    },
    getCampaign(params) {
        return api.get(`/api/admin/notification-campaign`, params);
    },
    editNotification(data) {
        return api.put(`/api/admin/notification-campaign`, data);
    },
    deleteNotification(id) {
        return api.delete(`/api/admin/notification-campaign/${id}`);
    },
};

export default API;
